import React from 'react';

import { withFirebase } from '../../Firebase';
import AuthEmailFormController from '../AuthEmailFormController';
import { OnBoardingStatus } from "../OnBoardingStatus";

const AuthSignInBase = (props) => {
  return <AuthEmailFormController
    requestNav={props.requestNav}
    firebaseMethod={props.firebase.doSignInWithEmailAndPassword}
    altMethod={OnBoardingStatus.SIGN_UP}
    title="Log In"
    altText="Don’t have an account?"
    altLinkText="Sign Up"
  />
};

export default withFirebase(AuthSignInBase);