export const supportTypesVolunteer = [ "Deliver groceries", "Cook meals", "Monetary donations" ];
export const supportTypesRequestee = [ "Groceries", "Cooked meals" ];
export const deliveryOptions = [ "Car/Bike", "Walk", "No delivery (Pick-up only)" ];
export const selfIsolationOptions = [ "Yes", "No" ];

export const labelName = "Name";
export const labelAddr = "Meeting point (e.g. Bloor St W & Ossington)";
export const labelCity = "City";
export const labelPostal = "Postal code";
export const labelProvince = "Province";
export const labelPhone = "Phone number";
export const labelStory = "Your story";
export const labelHouseSize = "Household size";
export const labelAssistanceTypes = "What kind of assistance do you need?"

export const titleLocationVolunteer = "Location";
export const titleLocationRequester = "Delivery location";
export const titleNeighbourhoodRequester = "Delivery neighbourhood";
export const msgLocationVolunteer = "Your location will not be shared with anyone.";
export const msgLocationRequester = "This will only be shared with the person who will deliver your groceries to you.";
export const msgNeighbourhoodRequester = "This will be shown to everyone using WeCare.";
export const msgPhoneVolunteer = "Your number will be shared with people whose grocery requests you accept.";
export const msgPhoneRequester = "Your phone number will be only shared with the volunteer who accepts your request. This is for the volunteer to confirm the delivery time and items requested.";

export const labelAcknowledge1Requester = 'I understand that my approximate location, phone number and COVID-19 story will be shared with volunteers.';
export const labelAcknowledge2Requester = 'I will respect the privacy of volunteers by keeping their personal information confidential.';
export const labelAcknowledge1Volunteer = 'I understand that I will be required to cover the costs of groceries myself.';
export const labelAcknowledge2Volunteer = 'I will respect the privacy of our community by keeping their personal information confidential.';

export const provinces = [
	"Alberta", "British Columbia", "Manitoba", "New Brunswick", 
	"Newfoundland and Labrador", "Northwest Territories", 
	"Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island",
  "Québec", "Saskatchewan", "Yukon"
];

export const roleVolunteer = "Volunteer";