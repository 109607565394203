import React from "react";
import { IconBack } from "../../icons";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { CommonStyle } from "../../constants/styles";

const BackLabelStyle = {
  backText: {
    marginLeft: '8px',
  },
  backWrapper: {
    marginBottom: '16px',
    cursor: 'pointer',
  },
};
const mergedStyle = { ...CommonStyle, ...BackLabelStyle }

const BackLabelComponent = ({ classes, onClickBack, backLabel }) => (
  <Grid
    alignItems="center"
    container
    className={classes.backWrapper}
    onClick={onClickBack}>
    <IconBack className={classes.icon12} />
    <Typography className={classes.backText}>
      { backLabel }
    </Typography>
  </Grid>
)

export default withStyles(mergedStyle)(BackLabelComponent);