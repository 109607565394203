import React, { Component } from 'react';
import clsx from 'clsx';

import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withFirebase } from '../../../Firebase';


import { CommonStyle } from '../../../../constants/styles';

const RequestCancelStyles = {
  description: {
    margin: '16px 0 10px',
  },
  firstButton: {
    marginRight: '11px'
  }
};

class RequestCancelModal extends Component {

  handleChange = (event) => {
    this.setState({
      reason: event.target.value
    });
  };

  cancel = () => {
    this.props.closeModal();
    this.setState({
      reason: ''
    });
  };

  submit = () => {
    this.props.submitCancellation(this.state.reason);
    this.setState({
      reason: ''
    });
    this.props.firebase.trackEvent('canceled_request' , {"reason": this.state.reason});  

  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
    this.state = {
      reason: ''
    };
  }

  render() {
    return (<Modal
      aria-labelledby="cancellation-modal-title"
      aria-describedby="cancellation-modal-desc"
      className={this.styles.modal}
      open={this.props.isModalShowing}
      onClose={this.cancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={this.props.isModalShowing}>
        <div className={this.styles.modalUi}>
          <Typography 
            id="cancellation-modal-title"
            className={clsx(this.styles.cardTitle, this.styles.noMargin)}>
            Cancel delivery
          </Typography>
          <Typography 
            id="cancellation-modal-desc"
            className={this.styles.description}
            variant="body1">
            Please let us know why you can no longer deliver. 
          </Typography>
          <TextField
            id="input-reason"
            name="reason"
            value={this.state.reason}
            fullWidth
            multiline
            rows={4}
            onChange={this.handleChange}
            InputLabelProps={{ shrink: true }}
            className={this.styles.topMarginLarge}
            variant="outlined" />
          <Grid justify="flex-end" container>
            <Button 
              variant="outlined"
              color="primary" 
              className={clsx(this.styles.modalButton, this.styles.firstButton)}
              onClick={this.cancel}>
              Go Back
            </Button>

            <Button 
              variant="contained"
              color="primary" 
              disabled={!this.state.reason}
              className={this.styles.modalButton}
              onClick={this.submit}>
              Confirm Cancellation
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>)
  }
}

const mergedStyles = { ...CommonStyle, ...RequestCancelStyles };
export default compose(withFirebase, withStyles(mergedStyles))(RequestCancelModal);