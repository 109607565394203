import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withBasicAuthorization } from '../../Session';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import ItemsComponent from './ItemsComponent';
import DeliveryComponent from './DeliveryComponent';
import { AppContent, AppEvents, withAppContext } from "../../App";
import { AppDataKey, LABEL_BACK_TO_ALL_REQUEST } from "../../../constants/appConstant";
import BackLabel from "../../Helper/BackLabel";
import { REQUESTS } from "../../../constants/routes";
import { logError, Tag } from "../../Helper/ErrorLogger";

export const NewRequestStatus = {
  ITEMS: 0,
  DELIVERY_DETAIL: 1,
  COMPLETE: 2,
  ERROR: 3,
};

class NewRequestComponentBase extends Component {
  requestNav = (navTargetId) => {
    const target = this.navComponents.find( candidate => candidate.id === navTargetId );
    if (target) {
      this.setState({
        mainPage: target.component,
      });
      if (target.id === NewRequestStatus.COMPLETE) {
        this.createRequest();
      }
    } else {
      logError(this.props.firebase.getUidSafe(), Tag.REQUEST_NEW, {
        reason: 'Cannot find navigation target with ID '+navTargetId
      });
    }
  };

  updateRequestData = (key, data, callback) => {
    this.setState({
      [key]: data
    }, callback);
  };

  onRequestDataChanged = () => {
    this.props.app.postEvent(AppEvents.ALLOW_NAV, false);
  };

  backToRequest = () => {
    this.props.history.push(REQUESTS);
    this.props.firebase.trackEvent('back_to_requests');
  };

  createRequest() {
    const uid = this.props.firebase.auth.currentUser.uid;
    this.props.firebase.orders().push({
      status: 0,
      requesteeName: this.props.firebase.auth.currentUser.displayName,
      deliveryItems: this.state.deliveryItems,
      requestee: uid,
      createTime: new Date().getTime(),
      deliveryAddress: this.state.deliveryAddress,
      deliveryTimeslots: this.state.deliveryTimeslots,
      specialInstruction: this.state.specialInstruction
    }).then(val => {
      this.props.app.postEvent(AppEvents.UPDATE_DATA, {
        [AppDataKey.SHOW_MY_REQUEST_ONLY]: true
      });
      this.props.app.postEvent(AppEvents.ALLOW_NAV, true);
      this.props.history.push(ROUTES.REQUESTS);
    }).catch(error => {
      logError(this.props.firebase.getUidSafe(), Tag.REQUEST_NEW, {
        ...error,
        networkCall: "createOrder"
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      mainPage: '',
      deliveryTimeslots: [],
      deliveryItems: [],
      specialInstruction: '',
      deliveryAddress: {}
    };
  }

  componentDidMount() {
    this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.NewRequest);
    const uid = this.props.firebase.auth.currentUser.uid;
    this.props.firebase.user(uid)
      .once('value', snapshot => {
        const profile = snapshot.val();
        this.setState({
          deliveryAddress: {
            address: profile.address,
            postal: profile.postalCode || '',
            city: profile.city,
            province: profile.province,
          }
        }, () => {
          this.navComponents = [
            {
              id: NewRequestStatus.ITEMS,
              component: <ItemsComponent
                requestNav={this.requestNav}
                updateRequestData={this.updateRequestData}
                onRequestDataChanged={this.onRequestDataChanged}
                data={this.state}/>
            },
            {
              id: NewRequestStatus.DELIVERY_DETAIL,
              component: <DeliveryComponent
                requestNav={this.requestNav}
                data={this.state}
                onRequestDataChanged={this.onRequestDataChanged}
                updateRequestData={this.updateRequestData} />
            },
            {
              id: NewRequestStatus.COMPLETE,
              component: ''
            }
          ];

          this.requestNav(NewRequestStatus.ITEMS);
        });
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_NEW, {
          ...error,
          networkCall: "getProfile"
        });
      });
  }

  render() {
    return (
      <div>
        <BackLabel
          backToRequest={this.backToRequest}
          backLabel={LABEL_BACK_TO_ALL_REQUEST} />
        { this.state.mainPage }
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  withAppContext,
  withBasicAuthorization
)(NewRequestComponentBase);