import React, { Component } from 'react';
import clsx from 'clsx';

import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../../../Firebase';


import { CommonStyle } from '../../../../constants/styles';

class RequestConnectModal extends Component {

  handleChange = (event) => {
    this.setState({
      neverShowAgain: event.target.checked
    });
    this.props.firebase.trackEvent('connect_popup_nevershow', {'value': !this.state.neverShowAgain});  

  };

  close = () => {
    this.props.onClose(this.state.neverShowAgain);
    this.setState({
      neverShowAgain: false
    });
    this.props.firebase.trackEvent('connect_popup_closed');  

  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
    this.state = {
      neverShowAgain: false
    };
  }

  render() {
    return (<Modal
      aria-labelledby="connect-modal-title"
      aria-describedby="connect-modal-desc"
      className={this.styles.modal}
      open={this.props.isModalShowing}
      onClose={this.close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={this.props.isModalShowing}>
        <div className={this.styles.modalUi}>
          <Typography 
            id="connect-modal-title"
            className={clsx(this.styles.cardTitle, this.styles.noMargin)}>
            Connect with {this.props.otherName}
          </Typography>
          <Typography 
            id="connect-modal-desc"
            style={{ margin: '16px 0 40px' }}
            variant="body1">
            Text on&nbsp;
            <Link 
              href={"tel:" + this.props.otherPhone}
              target="_blank"
              className={this.styles.hyperlink}>
              { this.props.otherPhone }
            </Link> to confirm the delivery time and items requested
          </Typography>
          <FormControlLabel
            control={<Checkbox
              size='small'
              inputProps={{ 
                'aria-checked': this.state.neverShowAgain || false 
              }} />
            }
            key="neverShowAgain"
            label="Don’t show this message again"
            onChange={this.handleChange}
            checked={this.state.neverShowAgain || false}
            name="neverShowAgain"
          />
          <Grid justify="flex-end" container>
            <Button 
              variant="contained"
              color="primary" 
              className={this.styles.modalButton}
              onClick={this.close}>
              Okay, got it
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>)
  }
}
export default compose(withFirebase, withStyles(CommonStyle))(RequestConnectModal);