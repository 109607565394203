import React, { Component } from 'react';

import AuthEmailForm from './views/AuthEmailForm';
import { withFirebase } from "../Firebase";
import { logError, Tag } from "../Helper/ErrorLogger";

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  showPassword: false,
};

class AuthEmailFormController extends Component {
	state = { ...INITIAL_STATE };

  onAuthSucceed = authUser => {
    this.setState({ ...INITIAL_STATE });
    this.props.firebase.trackEvent('auth_succeeded');
  };

  onAuthError = error => {
    logError("", Tag.AUTH, { error });
    this.setState({ error });
    this.props.firebase.trackEvent('auth_error');
  };

	handleChange = (prop) => (event) => {
    if (prop === 'showPassword') {
      this.setState({ 'showPassword': !this.state.showPassword });
    } else {
      this.setState({ [prop]: event.target.value });
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  onAltMethodRequested = () => {
    this.props.requestNav(this.props.altMethod);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.firebaseMethod(this.state.email, this.state.password)
      .then(this.onAuthSucceed)
      .catch(this.onAuthError);
  };

  handleSubmitProvider = (providerMethod) => {
    providerMethod()
      .then(this.onAuthSucceed)
      .catch(this.onAuthError);
  };

  isInvalid = () => {
  	return this.state.password === '' ||
      this.state.email === '';
  };

  render() {
  	return (
  		<AuthEmailForm 
  			{...this.props}
  			handleSubmit={this.handleSubmit}
        handleSubmitProvider={this.handleSubmitProvider}
  			onAltMethodRequested={this.onAltMethodRequested}
  			handleMouseDownPassword={this.handleMouseDownPassword}
  			handleChange={this.handleChange}
  			isInvalid={this.isInvalid}
  			email={this.state.email}
  			password={this.state.password}
  			showPassword={this.state.showPassword}
  			error={this.state.error}
  			/>
  	)
  }
}

export default withFirebase(AuthEmailFormController);