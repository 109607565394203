import React from 'react';
import { compose } from 'recompose';

import { withBasicAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import BaseProfileController from '../Profile/BaseProfileController';
import OnBoardingProfile from './views/OnBoardingProfile';
import { roleVolunteer } from '../../constants/profileConstants';
import { OnBoardingStatus } from "./OnBoardingStatus";
import { logError, Tag } from "../Helper/ErrorLogger";

class ProfileController extends BaseProfileController {

  getComponentName() {
    return "onboarding-profile";
  }

  componentDidMount() {
    const currentUser = this.props.firebase.auth.currentUser;
    this.setState({ 
      fullName: currentUser.displayName ? currentUser.displayName : '',
      phone: currentUser.phoneNumber ? currentUser.phoneNumber : '',
    });
    this.props.firebase.userRole(this.props.firebase.auth.currentUser.uid)
      .once('value', snapshot => {
        this.setState({
          isVolunteer: snapshot.val() === roleVolunteer
        });
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.PROFILE, {
          ...error,
          networkCall: "getUserRole",
          component: this.getComponentName()
        });
      });
  }

  onSubmitSuccess() {
    this.props.requestNav(OnBoardingStatus.PROFILE + 1);
    this.props.firebase.trackEvent('profile_completed');  
  }

  isInputInvalid() {
    return super.isInputInvalid()
      || !this.state.acknowledge1
      || !this.state.acknowledge2
  }

  renderContent() {
    return (
      <OnBoardingProfile 
        submitProfile={this.submitProfile}
        isVolunteer={this.state.isVolunteer}
        isInputInvalid={this.isInputInvalid} />
    );
  }
}

export default compose(
  withFirebase,
  withBasicAuthorization
)(ProfileController);