import * as React from "react";

function SvgIconPerson(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" {...props}>
      <path
        d="M5 5A2.333 2.333 0 105.001.335 2.333 2.333 0 005 5zm0 1.167C3.443 6.167.333 6.948.333 8.5v1.167h9.334V8.5c0-1.552-3.11-2.333-4.667-2.333z"
        fill="#4A4A4A"
      />
    </svg>
  );
}

export default SvgIconPerson;
