import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';

import { CommonStyle } from '../../constants/styles';
import {AppContent, AppEvents, withAppContext} from '../App';

const NavModalStyles = {
  lastButton: {
    marginLeft: 10
  }
};
const mergedStyles = { ...CommonStyle, ...NavModalStyles };

const blockNavContent = [
  AppContent.Profile,
  AppContent.EditRequest,
  AppContent.NewRequest
];

const componentKey = 'navCancelModal';

class NavCancelModal extends Component {

  handleBlockedNavigation = (location, action) => {
    if (!!this.lastLoc) {
      // already blocked, user insist, so reset and allow
      this.lastLoc = undefined;
      this.allowCurrentNav = false;
      return true;
    }

    if (blockNavContent.includes(this.currentContent) && !this.allowCurrentNav) {
      // block
      this.setState({
        isShowing: true
      });
      this.lastLoc = {
        action: action,
        path: location.pathname
      }
      return false;
    } else {
      // reset variable
      this.allowCurrentNav = false;
      // allow nav
      return true;
    }
  };

  handleNavConfirmation = (confirmed) => () => {
    this.setState({
      isShowing: false
    });
    if (confirmed) {
      // try to resume last navigation action
      if (this.lastLoc.action === 'POP') {
        this.props.history.goBack();
      } else {
        this.props.history.push(this.lastLoc.path);
      }
      this.props.firebase.trackEvent('confirm_cancel');
    } else {
      // clean up last location
      this.lastLoc = undefined;
      this.props.firebase.trackEvent('dismiss_cancel'); 
    }
  };

  onAppEvent = (eventKey, data) => {
    if (eventKey === AppEvents.CONTENT_CHANGED) {
      this.currentContent = data;
    } else if (eventKey === AppEvents.ALLOW_NAV) {
      this.allowCurrentNav = data;
    }
  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
    this.state = {
      isShowing: false
    };
    this.lastLoc = undefined;
    this.currentContent = undefined;
    this.allowCurrentNav = false;
  }

  componentDidMount() {
    this.props.app.subscribe(componentKey, this.onAppEvent);
  }

  componentWillUnmount() {
    this.props.app.unsubscribe(componentKey);
  }

  render() {
    return (
      <div>
        <Prompt message={this.handleBlockedNavigation}/>
        <Modal
          aria-labelledby="edit-confirmation-title"
          aria-describedby="edit-confirmation-desc"
          className={this.styles.modal}
          open={this.state.isShowing}
          onClose={this.handleNavConfirmation(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.isShowing}>
            <div className={this.styles.modalUi}>
              <Typography 
                id="edit-confirmation-title"
                className={this.styles.cardTitle}>
                Cancel editing
              </Typography>
              <Typography 
                id="edit-confirmation-desc"
                variant="body1">
                Any unsaved changes will be lost. 
              </Typography>
              <Grid 
                className={this.styles.modalButton}
                justify="flex-end" 
                container>
                <Button 
                  variant="outlined"
                  color="primary" 
                  onClick={this.handleNavConfirmation(false)}>
                  Continue editing
                </Button>
                <Button 
                  variant="contained"
                  color="primary" 
                  className={this.styles.lastButton}
                  onClick={this.handleNavConfirmation(true)}>
                  Confirm
                </Button>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default compose(
  withFirebase,
  withRouter,
  withAppContext,
  withStyles(mergedStyles)
)(NavCancelModal);