import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { withFirebase } from '../Firebase';
import { CommonStyle } from '../../constants/styles';
import { IconProfile, IconArrowDown, Logo } from '../../icons';
import {AppContent, AppEvents, withAppContext} from '../App';
import * as ROUTES from '../../constants/routes';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { roleVolunteer } from "../../constants/profileConstants";
import { Typography } from "@material-ui/core";
import { logError, Tag } from "../Helper/ErrorLogger";

const HeaderStyle = {
  container: {
    marginBottom: 36,
    backgroundColor: 'white',
    minWidth: 375,
    minHeight: 75
  },
  logo: {
    width: 101,
    marginLeft: '16px',
    cursor: 'pointer',
  },
  clickableButton: {
    cursor: 'pointer',
  },
  requestButton: {
    marginRight: 18
  },
  profileArrow: {
    paddingLeft: 8,
    width: 15
  },
  donateButton: {
    marginRight: 20
  }
};
const mergedStyles = { ...CommonStyle, ...HeaderStyle };

const MenuOptions = {
  PROFILE: 0,
  CONTACT_US: 1,
  TERMS_CONDITIONS: 2,
  PRIVACY_POLICY: 3,
  LOG_OUT: 4
};

const componentKey = 'header';

class Header extends Component {

  onMenuItemClicked = (index) => (event) => {
    this.closeMenu();
    this.props.firebase.trackEvent('dropdown_click');  
    switch(index) {
      case MenuOptions.PROFILE:
        this.props.firebase.trackEvent('dropdown_profile');  
        this.props.history.push(ROUTES.PROFILE);
        break;
      case MenuOptions.CONTACT_US:
        this.props.firebase.trackEvent('dropdown_contact');  
        window.open(ROUTES.FORM_GENERAL, "_blank");
        break;
      case MenuOptions.TERMS_CONDITIONS:
        this.props.firebase.trackEvent('dropdown_terms');  
        this.props.history.push(ROUTES.TERMS);
        break;
      case MenuOptions.PRIVACY_POLICY:
        this.props.firebase.trackEvent('dropdown_privacy');  
        this.props.history.push(ROUTES.PRIVACY_POLICY);
        break;
      case MenuOptions.LOG_OUT:
        this.props.firebase.trackEvent('dropdown_logout');  
        this.props.history.push(ROUTES.LOG_OUT);
        break;
      default:
        logError(this.props.firebase.getUidSafe(), Tag.HEADER, {
          reason: "Unrecognized menu item index "+index,
        });
        break;
    }
  };

  onEvent = (eventKey, data) => {
    if (eventKey === AppEvents.CONTENT_CHANGED) {
      this.currentContent = data;
      const onboardingCompleted = data > AppContent.OnBoarding;
      this.setState({
        isOnboardingCompleted: onboardingCompleted,
        shouldHideHeader: data === AppContent.Landing,
      });

      if (onboardingCompleted && this.state.isVolunteer == null) {
        // fetch user role
        this.props.firebase.userRole(this.props.firebase.auth.currentUser.uid)
          .once('value', snapshot => {
            this.setState({
              isVolunteer: snapshot.val() === roleVolunteer
            })
          }).catch(error => {
            logError(this.props.firebase.getUidSafe(), Tag.HEADER, {
              ...error,
              networkCall: "getUserRole"
            });
          });
      } else if (!onboardingCompleted) {
        // reset
        this.setState({
          isVolunteer: undefined
        })
      }
    }
  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
    this.onNewRequestClicked = this.onNewRequestClicked.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onHomeClicked = this.onHomeClicked.bind(this);
    this.state = {
      isOnboardingCompleted: false,
      shouldHideHeader: true,
      isVolunteer: undefined,
      anchorEl: null,
    }
  }

  componentDidMount() {
    this.props.app.subscribe(componentKey, this.onEvent);
  }

  componentWillUnmount() {
    this.props.app.unsubscribe(componentKey);
  }

  onNewRequestClicked() {
    this.props.history.push(ROUTES.NEW_REQUEST);
    this.props.firebase.trackEvent('create_new_request');  
  }

  openMenu(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  closeMenu() {
    this.setState({
      anchorEl: null
    })
  }

  onHomeClicked() {
    if (this.state.isOnboardingCompleted) {
      this.props.history.push(ROUTES.REQUESTS);
    } else if (this.currentContent === AppContent.OnBoarding) {
      this.props.history.push(ROUTES.LANDING);
    } else {
      this.props.history.goBack();
    }
    this.props.firebase.trackEvent('home_logo');
  }

  render() {
    return (
      this.state.shouldHideHeader ? null :
      <Grid
        container
        className={this.styles.container}
        alignItems="center"
        justify="space-between">

        <Logo 
          className={this.styles.logo}
          onClick={this.onHomeClicked}/>
        <Grid item style={{ paddingRight: "18px" }}>
          { this.state.isOnboardingCompleted ? <Grid container alignItems="center">
            {
              this.state.isVolunteer !== false ? null :
                <Button
                  className={this.styles.requestButton}
                  onClick={this.onNewRequestClicked}
                  variant="contained"
                  color="primary">
                  New Request
                </Button>
            }

            {
              this.state.isVolunteer &&
              <nav className={this.styles.donateButton}>
                <a href="https://www.gofundme.com/f/wecare-help-feed-a-family" target="_blank" className="button button-secondary button-wide-mobile button-sm" rel="noopener noreferrer">Donate</a>
              </nav>
            }
            <IconProfile 
              className={this.styles.clickableButton} 
              onClick={this.openMenu}/>
            <IconArrowDown 
              className={clsx(this.styles.clickableButton, this.styles.profileArrow)} 
              onClick={this.openMenu}/>
            <Menu
              id="user-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.state.anchorEl != null}
              onClose={this.closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <MenuItem onClick={this.onMenuItemClicked(MenuOptions.PROFILE)}>Profile</MenuItem>
              <MenuItem onClick={this.onMenuItemClicked(MenuOptions.CONTACT_US)}>Contact us</MenuItem>
              <MenuItem onClick={this.onMenuItemClicked(MenuOptions.TERMS_CONDITIONS)}>Terms & conditions</MenuItem>
              <MenuItem onClick={this.onMenuItemClicked(MenuOptions.PRIVACY_POLICY)}>Privacy Policy</MenuItem>
              <MenuItem onClick={this.onMenuItemClicked(MenuOptions.LOG_OUT)}>Log out</MenuItem>
            </Menu>
          </Grid> : <Grid container alignItems="center" justify="flex-end">
            { this.props.isLoggedIn ? <Typography
              className={this.styles.clickableButton}
              onClick={this.onMenuItemClicked(MenuOptions.LOG_OUT)}>
              Log out
            </Typography> : null }
          </Grid> }
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withAppContext,
  withStyles(mergedStyles),
  withRouter,
  withFirebase
)(Header);
