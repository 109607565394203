import React, { Component } from 'react';
import { compose } from 'recompose';
import clsx from 'clsx';

import { withFirebase } from '../../Firebase';
import { NewRequestStatus } from './index';
import { CommonStyle } from '../../../constants/styles';
import { CommonRequestStyles, getAddress, getTomorrowDate, timeslotCandidates } from '../../../constants/requestConstants';
import { IconAdd, IconCart, IconCalendar, IconLocation, IconDelete, IconNote, IconInfo, IconEdit } from '../../../icons';

import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from "@material-ui/core/Divider";
import { logError, Tag } from "../../Helper/ErrorLogger";

const DeliveryStyle = {
  addButton: {
    marginTop: "6px",
    paddingBottom: "0px",
  },
  cartIcon: {
    marginRight: "8px",
    marginLeft: "-3px"
  },
  subtitleIcon: {
    marginRight: "16px",
    marginTop: "-2px",
  },
  element: {
    margin: "16px 0 0",
    lineHeight: '24px'
  },
  dateItemWrapper: {
    border: '1px solid #E8E8E8',
    borderRadius: '4px',
    padding: '16px',
  },
  timeChip: {
    backgroundColor: '#fff',
    border: '1px solid #2196f3',
    color: '#2196F3'
  },
  clickableIcon: {
    cursor: 'pointer'
  },
  infoText: {
    fontSize: 13,
    marginLeft: 7,
    color: '#5c5c5c'
  },
};
const mergedStyle = { ...CommonStyle, ...CommonRequestStyles, ...DeliveryStyle };

class DeliveryComponentBase extends Component {
	
  onNext = event => {
  	// prevents page from refreshing
  	event.preventDefault();
    const dateSlots = this.state.deliveryTimeslots;
    dateSlots.forEach(dateItem => {
      // first remove the placeholder item on index 0
      dateItem.timeslots.shift();
      // then sort slots
      dateItem.timeslots.sort();
      // next generate actual date data
      dateItem.timeslots = dateItem.timeslots.map(slotIndex => {
        const time = new Date(dateItem.date.getTime());
        const slot = timeslotCandidates[slotIndex];
        time.setHours(slot.hourOffset);
        return ({
          start: time.getTime(),
          duration: slot.duration
        });
      });
      // convert date object to timestamp
      dateItem.date = dateItem.date.getTime();
    });
    this.updateTasks = 0;
    this.props.updateRequestData(
      "deliveryTimeslots", 
      this.state.deliveryTimeslots,
      this.onUpdateRequestDataComplete
    );
    this.props.updateRequestData(
      "specialInstruction", 
      this.state.specialInstruction,
      this.onUpdateRequestDataComplete
    );
  };

  onUpdateRequestDataComplete = () => {
    this.updateTasks++;
    if (this.updateTasks >= 2) {
      this.props.requestNav(NewRequestStatus.DELIVERY_DETAIL + 1);
      if (this.isEditMode) {
        this.props.firebase.trackEvent('updated_delivery_details');
      } else {
        this.props.firebase.trackEvent('submit_delivery_request');
      }
    }
  };

  onItemsClicked = event => {
    this.props.requestNav(NewRequestStatus.ITEMS);
    this.props.firebase.trackEvent('back_to_items_from_delivery');
  };

  handleTimeChange = (index) => (event) => {
    event.preventDefault();
    this.update(index, "timeslots", event.target.value)
    if (this.isEditMode) {
          this.props.firebase.trackEvent('updated_delivery_timeslots');
      } else {
          this.props.firebase.trackEvent('added_delivery_timeslots');
      }
  };

  handleDateChange = (index) => (date) => {
    this.update(index, "date", date)
    this.props.firebase.trackEvent('added_delivery_date');
  };

  handleInstructionChange = (event) => {
    this.setState({ specialInstruction: event.target.value });
    this.props.onRequestDataChanged();
  };

  onDeliverySlotAction = (index, key) => (event) => {
    event.preventDefault();
    this.update(index, key);
  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
    this.isEditMode = props.isEdit;

    const deliverySlots = props.data.deliveryTimeslots.map(dateSlot => {
      const dateTimestamp = dateSlot.date;
      const timeslotsArray = [ "Select one or multiple timeslots" ];
      dateSlot.timeslots.forEach(timeslot => {
        // needs to find which slot in timeslotCandidates this fits in
        const hourDiff = (timeslot.start - dateTimestamp) / 1000 / 3600;
        let index = timeslotCandidates.findIndex(candidate => candidate.hourOffset === hourDiff);
        if (index < 0) {
          logError(this.props.firebase.getUidSafe(), Tag.REQUEST_NEW, {
            reason: "Cannot find timeslot candidate for hourDiff "+hourDiff,
            date: dateTimestamp,
            timeslot: timeslot
          });
          index = 0
        }
        timeslotsArray.push(index);
      })

      return ({
        date: new Date(dateSlot.date),
        timeslots: timeslotsArray
      })
    })

    this.state = {
      deliveryTimeslots: deliverySlots,
      specialInstruction: props.data.specialInstruction,
    }
  }

  componentDidMount() {
    if (this.state.deliveryTimeslots.length === 0) {
      this.update(0, "add");
    }
  }

  update(itemIndex, key, value) {
    const newDeliverySlots = this.state.deliveryTimeslots;
    if (key === "timeslots" || key === "date") {
      newDeliverySlots[itemIndex][key] = value;
    } else if (key === "delete") {
      newDeliverySlots.splice(itemIndex, 1);
      this.props.firebase.trackEvent('deleted_timeslot');  
    } else if (key === "add") {
      newDeliverySlots.push({
        date: getTomorrowDate(),
        timeslots: [ "Select one or multiple timeslots" ],
      });
      this.props.firebase.trackEvent('add_another_timeslot');  
    }

    this.setState({
      deliveryTimeslots: newDeliverySlots
    });

    this.props.onRequestDataChanged();
  };

  areItemsInvalid() {
    const today = new Date();
    today.setHours(0,0,0,0);
    return this.state.deliveryTimeslots.length === 0 ||
      this.state.deliveryTimeslots.some(dateItem => 
        dateItem.date === '' 
          || dateItem.date.getTime() < today.getTime() 
          || dateItem.timeslots.length <= 1
      );
  }

  render() {
    return (
      <form onSubmit={this.onNext}>
        <Card className={this.styles.cardRoot}>
          <CardContent>
            <Typography className={this.styles.cardTitle}>
              {
                this.isEditMode ? "Edit delivery details " : "Delivery details"
              }
            </Typography>

            {
              this.isEditMode ? null : this.renderItemsSection()
            }

            <Divider />

            <Grid 
              alignItems="center"
              container>
              <IconCalendar className={this.styles.subtitleIcon} />
              <Typography variant="subtitle1">
                Delivery Times
              </Typography>
            </Grid>

		        <Typography 
              variant="body1" 
              className={this.styles.element}>
              Choose the dates and timeslots that suit your schedule. Once your request is accepted, you will still need to confirm with the volunteer on an exact time. 
            </Typography>

            {this.state.deliveryTimeslots.map((dateItem, index) => (
              <div 
                key={"dateItem"+index}
                className={clsx(this.styles.dateItemWrapper, this.styles.element)}>
                <Grid container justify="flex-end">
                  <IconDelete 
                    className={this.styles.clickableIcon} 
                    onClick={this.onDeliverySlotAction(index, "delete")}/>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    fullWidth
                    autoOk={true}
                    emptyLabel="Select a date"
                    format="MMM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    TextFieldComponent={(props) => (<TextField {...props} variant="outlined"  />)}
                    minDate={getTomorrowDate()}
                    minDateMessage="Please choose a future date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                           <IconCalendar className={this.styles.clickableIcon} />
                        </InputAdornment>
                      )
                    }}
                    value={dateItem.date ? new Date(dateItem.date) : undefined}
                    onChange={this.handleDateChange(index)}
                  />
                </MuiPickersUtilsProvider>
                
                <FormControl 
                  fullWidth 
                  variant="outlined"
                  className={this.styles.element}>
                  <InputLabel id="timeslot-label" shrink={true}>Desired Timeslots</InputLabel>
                  <Select
                    labelId="timeslot-label"
                    id="timeslot-chip"
                    multiple
                    value={dateItem.timeslots}
                    label="Desired Timeslots"
                    onChange={this.handleTimeChange(index)}
                    displayEmpty={true}
                    renderValue={(selected) => (
                      <div>
                        {selected.length === 1 ?
                          selected[0] :
                          selected.slice(1).map((slotIndex) => (
                          <Chip 
                            key={slotIndex} 
                            label={timeslotCandidates[slotIndex].displayText} 
                            className={this.styles.timeChip}/>
                        ))}
                      </div>
                    )}>
                    {timeslotCandidates.map((timeslot, slotIndex) => (
                      <MenuItem key={"timeslot"+slotIndex} value={slotIndex}>
                        <Checkbox
                          checked={dateItem.timeslots.indexOf(slotIndex) >= 0}
                          size='small'/>
                        <ListItemText primary={timeslot.displayText} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ))}

            <Button 
              color="primary"
              onClick={this.onDeliverySlotAction(0, "add")}
              className={this.styles.addButton}
              startIcon={<IconAdd className={this.styles.addIcon} />} >
              Add Another Timeslot
            </Button>

            <Divider />

            <Grid 
              alignItems="flex-end"
              container>
              <IconNote className={this.styles.subtitleIcon} />
              <Typography variant="subtitle1">
                Special instructions
              </Typography>
            </Grid>

            <TextField
              id="specialInstruction"
              name="specialInstruction"
              label="Add a note (e.g. leave on front porch)"
              className={this.styles.element}
              value={this.state.specialInstruction}
              onChange={this.handleInstructionChange}
              fullWidth
              multiline
              rows={4}
              InputLabelProps={{ shrink: true }}
              variant="outlined" />

            <Divider />

            <Grid 
              alignItems="flex-end"
              container>
              <IconLocation className={this.styles.subtitleIcon} />
              <Typography variant="subtitle1">
                Delivery Address
              </Typography>
            </Grid>

            <Grid 
              container 
              justify="space-between"
              className={this.styles.element}>
              <Typography variant="body1" className={this.styles.lineBreakText}>
                { getAddress(true, this.props.data.deliveryAddress) }
              </Typography>
            </Grid>

            <Grid 
              container 
              alignItems="center" 
              className={this.styles.element}>
              <IconInfo className={this.styles.icon12} />
              <Typography variant="body1" className={this.styles.infoText}>
                Your address can be updated by going to ‘Profile’. 
              </Typography>
            </Grid>
          </CardContent>

          <div className={this.styles.space100}/>

          <CardActions>
            { this.renderButtonSection() }
          </CardActions>
        </Card>
      </form>
    );
	}

	renderItemsSection() {
    return (
      <div>
        <Grid justify="space-between" alignItems="center" container>
          <IconCart className={this.styles.subtitleIcon} />
          <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
            {this.props.data.deliveryItems.length} {this.props.data.deliveryItems.length === 1 ? "item" : "items"}
          </Typography>

          <Button
            className={this.styles.edit}
            color="primary"
            onClick={this.onItemsClicked}
            startIcon={<IconEdit className={this.styles.icon18} />}>
            Edit
          </Button>
        </Grid>
        <div style={{ marginTop: 16 }}>
          {this.props.data.deliveryItems.map((item, index) => (
            <Typography variant="body1" key={item.name+index}>
              {item.quantity} x {item.name}
            </Typography>
          ))}
        </div>
      </div>
    );
  }

  renderButtonSection() {
    return this.isEditMode ? (<CardActionArea>
      <Button
        fullWidth
        disabled={this.areItemsInvalid()}
        variant="contained"
        color="primary"
        type="submit">
        Save Changes
      </Button>
      <Button
        fullWidth
        variant="outlined"
        className={this.styles.element}
        onClick={this.props.cancel}
        color="primary">
        Cancel Editing
      </Button>
    </CardActionArea>) : (<Button
      fullWidth
      disabled={this.areItemsInvalid()}
      variant="contained"
      color="primary"
      type="submit">
      Submit Request
    </Button>)
  }
}

export default compose(withFirebase, withStyles(mergedStyle))(DeliveryComponentBase);