class BasicStore {
  subscribers = [];

  // region subscriber methods
  subscribe = (key, onEvent) => {
    this.subscribers.push({
      key: key,
      onEvent: onEvent
    });
  };
  unsubscribe = (key) => {
    const targetIndex = this.subscribers.findIndex(candidate => candidate.key === key);
    if (targetIndex >= 0) {
      this.subscribers.splice(targetIndex, 1);
    }
  };
  notifySubscribers = (eventKey, data) => {
    this.subscribers.forEach(subscriber => subscriber.onEvent(eventKey, data));
  };
  // endregion
}

export default BasicStore;