import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import { IS_PROD } from "../../constants/appConstant";

const firebaseConfigDev = {
  apiKey: "AIzaSyCO791PEqdTdQUVolrbfERxTzW_RjJAvC4",
  authDomain: "wecare-9aa48.firebaseapp.com",
  databaseURL: "https://wecare-9aa48.firebaseio.com",
  projectId: "wecare-9aa48",
  storageBucket: "wecare-9aa48.appspot.com",
  messagingSenderId: "277571826447",
  appId: "1:277571826447:web:f7f0b5a4869938c87ac47a",
  measurementId: "G-2EGT36ZYGS"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyAdkAlme2GKUFkNKCL5r88j1jOB14O5nNQ",
  authDomain: "wecareprod.firebaseapp.com",
  projectId: "wecareprod",
  storageBucket: "wecareprod.appspot.com",
  messagingSenderId: "474613999884",
  appId: "1:474613999884:web:af78a728988a8bce58c129",
  measurementId: "G-53Q62X53K7"
};

const googleProvider = new app.auth.GoogleAuthProvider();
const facebookProvider = new app.auth.FacebookAuthProvider();
facebookProvider.addScope('email');

class Firebase {
	constructor() {
		app.initializeApp(IS_PROD ? firebaseConfigProd : firebaseConfigDev);
		this.auth = app.auth();
    this.db = app.database();
    this.analytics = app.analytics();
	}

	// *** Auth API ***
	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);
	doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(googleProvider);
  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(facebookProvider);
  doSignOut = () => this.auth.signOut();

  // *** UserProfile API ***
  user = uid => this.db.ref(`users/${uid}`);
  userRole = uid => this.db.ref(`users/${uid}/role`);

  // *** Request API ***
  order = orderId => this.db.ref(`orders/${orderId}`);
  orders = () => this.db.ref('orders');

  // *** Request cancellation reason API (temp) ***
  cancelReason = () => this.db.ref('cancelReason');

  // *** Analytics API ***
  trackEvent = (eventName, eventParams) => this.analytics.logEvent(eventName, eventParams);
  setUserProperties = (property) => this.analytics.setUserProperties(property);

  // helper
  getUidSafe = () => {
    if (!!this.auth.currentUser) {
      return this.auth.currentUser.uid;
    } else {
      return '';
    }
  }
}
 
export default Firebase;