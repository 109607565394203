import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Firebase, { FirebaseContext } from './components/Firebase';
import { App } from './components/App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const appTheme = createMuiTheme({
  palette: {
    primary: {
    	main: '#0776DF',
    	contrastText: "#fff",
    },
    secondary: {
      main: '#0776DF',
    },
    text: {
      primary: '#292929'
    },
    action: {
      disabled: 'rgba(7, 118, 223, 0.5)',
      disabledOpacity: 0.5,
      disabledBackground: 'rgba(7, 118, 223, 0.5)'
    }
  },
  typography: {
    fontFamily: 'Cabin',
    h2: {
      lineHeight: "24px",
      letterSpacing: '0.0015em',
    },
    h6: {
      fontSize: '20px',
      letterSpacing: '0.0015em',
    },
    subtitle1: {
      lineHeight: "24px",
      letterSpacing: '0.0015em',
      fontSize: 20,
      fontWeight: 500
    },
    body1: {
      color: '#292929',
      lineHeight: "24px",
      fontSize: '16px',
      letterSpacing: '0.0015em',
    }
  },
  overrides: {
    MuiGrid: {
      container: {
        flexWrap: "nowrap"
      }
    },
  	MuiCardActions: {
  		root: {
  			padding: "26px"
  		}
  	},
    MuiCardContent: {
      root: {
        padding: "26px",
        "&:last-child": {
          paddingBottom: "26px"
        }
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: "24px",
        color: '#5c5c5c',
      }
    },
    MuiButton: {
      root: {
        textTransform: "none"
      },
      outlined: {
        '&.Mui-disabled': {
          border: '1px solid rgba(7, 118, 223, 0.5)'
        }
      },
      contained: {
        '&.Mui-disabled': {
          color: '#fff'
        }
      },
      label: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    MuiTab: {
      root: {
        textTransform: "none"
      },
      textColorPrimary: {
        color: '#292929'
      }
    },
    MuiCheckbox: {
  	  root: {
  	    color: '#C1C1C1',
  	    paddingRight: '14px'
      }
    },
    MuiDivider: {
  	  root: {
  	    backgroundColor: '#DDDDDD',
        margin: '16px 0'
      }
    }
  }
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
  	<ThemeProvider theme={appTheme}>
    	<App />
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
