import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
 
import { withBasicAuthorization } from '../Session';
import BaseProfileController from './BaseProfileController';

import { withFirebase } from '../Firebase';
import {AppContent, AppEvents, withAppContext} from '../App';
import MyProfile from './views/MyProfile';
import * as ProfileUtil from '../../constants/profileConstants';
import { REQUESTS } from "../../constants/routes";
import { AppDataKey } from "../../constants/appConstant";
import Toast from "../Helper/Toast";
import { logError, Tag } from "../Helper/ErrorLogger";

class MyProfileController extends BaseProfileController {

  getComponentName() {
    return "my-profile";
  }

  backToRequest = () => {
    // make sure requests page shows community request tab
    this.props.app.postEvent(AppEvents.UPDATE_DATA, {
      [AppDataKey.SHOW_MY_REQUEST_ONLY]: false
    })
    this.props.history.push(REQUESTS);
    this.props.firebase.trackEvent('back_to_requests');
  };

  onDataChanged() {
    super.onDataChanged();
    this.props.app.postEvent(AppEvents.ALLOW_NAV, false);
  }

  onSubmitError() {
    super.onSubmitError();
    this.setState({
      toastSeverity: 'error'
    });
  }

  onSubmitSuccess() {
    super.onSubmitSuccess();
    this.setState({
      toastSeverity: 'success'
    });
    this.props.app.postEvent(AppEvents.ALLOW_NAV, true);
  }

  componentDidMount() {
    this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.Profile);
    this.props.app.postEvent(AppEvents.ALLOW_NAV, true);
    const uid = this.props.firebase.auth.currentUser.uid;
    this.props.firebase.user(uid)
      .once('value', snapshot => {
        const profile = snapshot.val();
        this.setState({
          fullName: profile.fullName,
          address: profile.address,
          city: profile.city,
          province: profile.province,
          postalCode: profile.postalCode,
          phone: profile.phone,
          householdSize: profile.householdSize,
          supportRequest: this.getSelectionFromDB(profile.supportRequest, ProfileUtil.supportTypesRequestee),
          myStory: profile.myStory,
          isVolunteer: profile.role === ProfileUtil.roleVolunteer,
          errormessage: ''
        });
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.PROFILE, {
          ...error,
          networkCall: "getProfile",
          component: this.getComponentName()
        });
      });
  }

  getSelectionFromDB(cloudInput, reference) {
    const result = new Array(reference.length);
    if (cloudInput == null) {
      result.fill(false);
    } else {
      cloudInput.forEach(input => {
        const ind = reference.findIndex(ref => ref === input);
        result[ind] = ind >= 0 && ind < result.length;
      });
    }
    return result;
  }

  renderContent() {
    return (
      <div>
        <MyProfile
          submitProfile={this.submitProfile}
          isVolunteer={this.state.isVolunteer}
          backToRequest={this.backToRequest}
          isInputInvalid={this.isInputInvalid} />
        <Toast
          level={this.state.toastSeverity}
          errorMsg='Something went wrong. Please try again.'
          successMsg='Saved successfully'
          onClose={() => this.setState({ toastSeverity: undefined })}
        />
      </div>
    );
  }
}

 
export default compose(
  withBasicAuthorization, 
  withRouter,
  withAppContext,
  withFirebase
)(MyProfileController);