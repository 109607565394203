import * as React from "react";

function SvgIconArrowDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 4" fill="none" {...props}>
      <path
        d="M6.178 0L3.5 2.472.822 0 0 .761 3.5 4 7 .761 6.178 0z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgIconArrowDown;
