import React, { useState } from "react";
import { compose } from "recompose";

import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import RequestItemComponent from "./RequestItemComponent";
import { AppDataKey, AppEvents } from "../../../constants/appConstant";
import { CommonStyle } from "../../../constants/styles";
import { withAppContext } from "../../App";
import { withRequestListStore } from "./WithRequestListStore";

const RequestListStyle = {
  emptyTitle: {
    textAlign: 'center',
    marginTop: '15px',
  },
  emptyMessage: {
    color: '#5C5C5C',
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '5px',
  }
};
const mergedStyle = { ...CommonStyle, ...RequestListStyle };

function getEmptyTitle(isVolunteer, showMyRequestsOnly) {
  if (showMyRequestsOnly) {
    if (isVolunteer) {
      return 'You haven’t accepted any requests yet.';
    } else {
      return 'You have no requests yet.';
    }
  } else {
    return 'No community requests yet.';
  }
}

function getEmptyMessage(isVolunteer, showMyRequestsOnly) {
  if (showMyRequestsOnly) {
    if (isVolunteer) {
      return 'Accepted requests will be shown here.';
    } else {
      return 'New requests made will be shown here.';
    }
  } else {
    return 'Requests made by your community will be shown here.';
  }
}

const RequestListUI = ({ isLoading, classes, userIsVolunteer, store, app }) => {

  const [showMyRequests, setShowMyRequests] = useState(app.getData(AppDataKey.SHOW_MY_REQUEST_ONLY) || false);

  const onTabChanged = (event, newValue) => {
    setShowMyRequests(newValue === 1);
    app.postEvent(AppEvents.UPDATE_DATA, {
      [AppDataKey.SHOW_MY_REQUEST_ONLY]: newValue === 1
    });
  };

  const currentList = showMyRequests ? store.myList : store.fullList;

  return <div>
    { isLoading ?
      <CircularProgress /> :
      <Card className={classes.cardRoot}>
        <Paper square >
          <Tabs
            value={showMyRequests ? 1 : 0}
            centered={true}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            onChange={onTabChanged}
            aria-label="request list tabs">
            <Tab label="Community Requests" />
            <Tab label={"Your Requests ("+store.myList.length+")"} />
          </Tabs>
        </Paper>
        <CardContent>
          {
            currentList.length === 0 ?
              <Container>
                <Typography className={classes.emptyTitle}>
                  {
                    getEmptyTitle(userIsVolunteer, showMyRequests)
                  }
                </Typography>
                <Typography className={classes.emptyMessage}>
                  {
                    getEmptyMessage(userIsVolunteer, showMyRequests)
                  }
                </Typography>
              </Container> :
              currentList.map((request, index) => (
                <RequestItemComponent
                  key={"requestItem"+index}
                  showMyRequestsOnly={showMyRequests}
                  request={request} />
              ))
          }
        </CardContent>
      </Card>
    }
  </div>;
}

export default compose(
  withStyles(mergedStyle),
  withAppContext,
  withRequestListStore,
)(RequestListUI);