import React, { Component } from 'react';

import { CommonStyle } from '../../../constants/styles';
import GeneralInfo from '../../Profile/views/GeneralInfo';
import RequesterDetail from '../../Profile/views/RequesterDetail';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import ProfileAcknowledgement from "./ProfileAcknowledgement";

class OnBoardingProfile extends Component {

  constructor(props) {
    super(props);
    this.styles = props.classes;
  }

  render() {
    return (
      <form onSubmit={this.props.submitProfile}>
        <Card className={this.styles.cardRoot}>
          <CardContent>
            <Typography className={this.styles.cardTitle}>
              Tell us about yourself
            </Typography>

            <GeneralInfo isVolunteer={this.props.isVolunteer} />
            {this.props.isVolunteer ? null : <RequesterDetail />}

            <ProfileAcknowledgement isVolunteer={this.props.isVolunteer} />
          </CardContent>

          <CardActions>
            <Button 
              fullWidth
              disabled={this.props.isInputInvalid()}
              variant="contained" 
              color="primary" 
              type="submit" >
              Finish
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  }
}

export default withStyles(CommonStyle)(OnBoardingProfile);