import * as React from "react";

function SvgIconNote(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M16 0H1.99C.89 0 0 .9 0 2l.01 14c0 1.1.89 2 1.99 2h10l6-6V2c0-1.1-.9-2-2-2zM4 5h10v2H4V5zm5 6H4V9h5v2zm2 5.5V11h5.5L11 16.5z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgIconNote;
