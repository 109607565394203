import * as React from "react";

function SvgIconWarning(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
      <path
        d="M8.5.583A7.92 7.92 0 00.583 8.5 7.92 7.92 0 008.5 16.417 7.92 7.92 0 0016.417 8.5 7.92 7.92 0 008.5.583zm0 8.709a.794.794 0 01-.792-.792V5.333c0-.435.357-.791.792-.791.435 0 .792.356.792.791V8.5a.794.794 0 01-.792.792zm.792 3.166H7.708v-1.583h1.584v1.583z"
        fill="#D14904"
      />
    </svg>
  );
}

export default SvgIconWarning;
