import * as React from "react";

function SvgIconCart(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M5 16c-1.1 0-1.99.9-1.99 2S3.9 20 5 20s2-.9 2-2-.9-2-2-2zM0 2h1l3.6 7.59-1.35 2.44C2.52 13.37 3.48 15 5 15h11c.55 0 1-.45 1-1s-.45-1-1-1H5l1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0018.01 2H3.21L2.54.57a.993.993 0 00-.9-.57H0c-.55 0-1 .45-1 1s.45 1 1 1zm15 14c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIconCart;
