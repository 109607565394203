import * as React from "react";

function SvgIconDelivery(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31 23" fill="none" {...props}>
      <path
        d="M26.5 6h-4.125V.5H3.125a2.758 2.758 0 00-2.75 2.75v15.125h2.75A4.12 4.12 0 007.25 22.5a4.12 4.12 0 004.125-4.125h8.25A4.12 4.12 0 0023.75 22.5a4.12 4.12 0 004.125-4.125h2.75V11.5L26.5 6zM7.25 20.438a2.06 2.06 0 01-2.063-2.063 2.06 2.06 0 012.063-2.063 2.06 2.06 0 012.063 2.063 2.06 2.06 0 01-2.063 2.063zM25.813 8.063l2.695 3.437h-6.133V8.062h3.438zM23.75 20.437a2.06 2.06 0 01-2.063-2.062 2.06 2.06 0 012.063-2.063 2.06 2.06 0 012.063 2.063 2.06 2.06 0 01-2.063 2.063z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIconDelivery;
