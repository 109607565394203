import * as React from "react";

function SvgIconGoogle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 400 407.333" {...props}>
      <g fillRule="evenodd">
        <path
          d="M204 206.167v39.5h54.833c48.652 0 54.834.056 54.834.502 0 2.456-3.605 14.127-6.21 20.106-6.315 14.496-17.393 28.471-29.903 37.724l-4.28 3.165.946.734 32.947 25.557 32 24.823 4.947-4.889c31.471-31.101 50.711-74.584 54.889-124.056 1.599-18.92.579-45.295-2.348-60.725-.177-.935-.322-1.754-.322-1.82 0-.067-43.275-.121-96.166-.121H204v39.5"
          fill="#4484f3"
        />
        <path
          d="M55.835 268.851c-18.515 14.375-33.716 26.291-33.778 26.478-.156.468 3.4 7.126 7.035 13.171 43.076 71.638 123.221 108.831 208.241 96.639 37.982-5.446 70.576-19.847 97.75-43.186 2.155-1.85 3.917-3.472 3.917-3.605 0-.232-63.641-49.697-65.161-50.647-.691-.431-1.008-.3-4.355 1.801-26.645 16.726-65.797 21.648-98.003 12.321-36.902-10.688-69.016-41.394-80.954-77.406-.32-.963-.681-1.74-.804-1.727-.123.013-15.372 11.786-33.888 26.161"
          fill="#34ab54"
        />
        <path
          d="M19.921 115.909c-26.572 56.267-26.208 124.65.945 177.474l.967 1.882 33.5-25.978c18.425-14.288 33.696-26.174 33.934-26.412.357-.356.229-1.113-.714-4.238-6.949-23.01-6.766-49.6.491-71.587.589-1.783.627-2.184.244-2.545-.25-.235-15.23-11.863-33.288-25.841-18.058-13.977-33.147-25.697-33.53-26.046l-.696-.633-1.853 3.924"
          fill="#fbbb04"
        />
        <path
          d="M191 .212c-11.524.99-18.437 1.901-26.5 3.49A203.753 203.753 0 0035.273 89.167c-4.796 7.059-13.299 21.589-13.261 22.662.007.186 15.261 12.146 33.898 26.579l33.886 26.241 1.769-4.741c29.049-77.844 128.2-104.819 187.75-51.079l2.815 2.54 29.172-29.171 29.172-29.172-.82-.882c-3.729-4.01-16.309-13.908-24.987-19.659C288.061 14.853 259.466 4.597 226.333.804 221.753.28 195.356-.163 191 .212"
          fill="#ec4434"
        />
      </g>
    </svg>
  );
}

export default SvgIconGoogle;
