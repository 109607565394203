import * as React from "react";

function SvgIconEdit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M.125 15.25V19h3.906L15.552 7.94l-3.906-3.75L.125 15.25zM18.573 5.04a.968.968 0 000-1.41l-2.438-2.34a1.068 1.068 0 00-1.468 0L12.76 3.12l3.907 3.75 1.906-1.83z"
        fill="#0776DF"
      />
    </svg>
  );
}

export default SvgIconEdit;
