import React, { useEffect } from "react";
import { AppContent, AppEvents } from "../../constants/appConstant";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import { compose } from "recompose";
import { withAppContext } from "../App";
import { withStyles } from "@material-ui/core/styles";
import { CommonStyle } from "../../constants/styles";
import { FORM_GENERAL } from "../../constants/routes";

const UserDataDeletionPolicy = ({ classes, app }) => {
  useEffect(() => {
    app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.OTHER_NO_AUTH);
  });

  return (
    <Card className={classes.cardRoot}>
      <CardContent>
        <h1>User Data Deletion Instruction for WeCare</h1>

        <p>At WeCare, accessible from wecare-app.ca, one of our main priorities is the data safety of our visitors.</p>

        <p>Please use&nbsp;
          <Link
            className={classes.hyperlink}
            target="_blank"
            href={FORM_GENERAL}>
            this form
          </Link> to request your data to be deleted. We will get back to you shortly.
        </p>

        <p>Additionally, if you signed up with Facebook, you can remove WeCare's access from your Facebook settings following&nbsp;
          <Link
            className={classes.hyperlink}
            target="_blank"
            href="https://www.facebook.com/help/170585223002660?helpref=faq_content">
            this instruction
          </Link>.
        </p>

        <p>If you have additional questions or require help to delete your data, do not hesitate to contact us.</p>

      </CardContent>
    </Card>
  )
}

export default compose(withAppContext, withStyles(CommonStyle))(UserDataDeletionPolicy);