import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { CommonStyle } from '../../../constants/styles';
import RequesterDetail from './RequesterDetail';
import GeneralInfo from './GeneralInfo';
import { LABEL_BACK_TO_ALL_REQUEST } from "../../../constants/appConstant";
import BackLabel from "../../Helper/BackLabel";

class MyProfile extends Component {

	constructor(props) {
		super(props);
    this.styles = props.classes;
	}

	render() {
		return (
		  <div>
        <BackLabel
          backToRequest={this.props.backToRequest}
          backLabel={LABEL_BACK_TO_ALL_REQUEST} />
        <form onSubmit={this.props.submitProfile}>
          <Card className={this.styles.cardRoot}>
            <CardContent>
              <Typography className={this.styles.cardTitle}>
                Tell us about yourself
              </Typography>

              <GeneralInfo isVolunteer={this.props.isVolunteer} />
              {this.props.isVolunteer ? null : <RequesterDetail />}

            </CardContent>

            <CardContent>
              <Button
                fullWidth
                disabled={this.props.isInputInvalid()}
                variant="contained"
                color="primary"
                type="submit" >
                Save Changes
              </Button>
            </CardContent>
          </Card>
        </form>
      </div>
		);
	}
}

export default withStyles(CommonStyle)(MyProfile);