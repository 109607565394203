const CommonStyle = {
  cardRoot: {
    // Needed for T&C until we revamp it to use Typography
    fontFamily: 'Cabin',
  	maxWidth: 600,
  	minWidth: 340,
  	minHeight: 310,
  },
  roboto: {
  	fontFamily: 'Roboto'
  },
  space100: {
  	height: '50px',
  },
  cardTitle: {
    margin: '0 0 26px 0',
    fontWeight: '600',
    fontFamily: 'Cabin',
    fontSize: '24px',
    lineHeight: '36px',
  },
  sectionTitle: {
    fontWeight: '600',
    margin: '26px 0 6px',
  },
  hyperlink: {
    cursor: 'pointer',
    color: '#0776DF !important',
    textDecoration: 'underline !important'
  },
  icon24: {
    width: 24,
    height: 24
  },
  icon18: {
    width: 18,
    height: 18
  },
  icon12: {
    width: 12,
    height: 12
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalUi: {
    backgroundColor: '#ffffff',
    padding: 16,
    outline: 0,
    minWidth: 304,
    maxWidth: 475,
    width: 'calc(80vw)',
  },
  modalButton: {
    marginTop: 26,
  },
  secondFullWidthButton: {
    marginTop: 16
  },
  lineBreakText: {
    whiteSpace: 'pre-wrap'
  },
  noMargin: {
    margin: 0
  }
};

export { CommonStyle };