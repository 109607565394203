import React, { useEffect } from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";

import { CommonStyle } from '../../../../constants/styles';
import * as RequestUtil from '../../../../constants/requestConstants';
import {
  IconCalendar,
  IconLocation,
  IconPhone,
  IconPerson,
  IconEdit,
  IconNote,
  IconHouse
} from '../../../../icons';
import BackLabel from "../../../Helper/BackLabel";
import { compose } from "recompose";
import { AppEvents, withAppContext } from "../../../App";
import { AppDataKey, LABEL_BACK_TO_ALL_REQUEST, LABEL_BACK_TO_MY_REQUEST } from "../../../../constants/appConstant";

const RequestDetailStyles = {
  edit: {
    fontSize: 16,
    padding: 0,
  },
  section: {
    marginTop: 16,
    marginBottom: 16,
  },
  deliveryDetailIcon: {
    marginTop: '6px',
    marginRight: "11px",
  },
  helperText: {
    color: '#5C5C5C',
    fontSize: 13,
  },
  expireTextWrapper: {
    margin: '-16px 0 0 22px'
  },
  statusLabelWrapper: {
    marginTop: '3px'
  },
  itemsWrapper: {
    marginTop: '13px',
  },
};
const mergedStyles = { 
  ...CommonStyle, 
  ...RequestUtil.CommonRequestStyles, 
  ...RequestDetailStyles 
};

// region Helper methods
function getDateString(timestamp) {
  return new Date(timestamp)
    .toLocaleDateString(undefined, {month: 'long', day: 'numeric', weekday: 'short'});
}

function getTimeString(timeslot) {
  const date = new Date(timeslot.start);
  const options = {hour: '2-digit', minute: '2-digit', hour12: true};
  const startString = date.toLocaleTimeString(undefined, options);
  date.setHours(date.getHours() + timeslot.duration);
  const endString = date.toLocaleTimeString(undefined, options);
  return startString + " - " + endString;
}

function getMainButtonText(requestStatus, requestAuthStatus) {
  if (!RequestUtil.isMyRequest(requestAuthStatus) && RequestUtil.isOpen(requestStatus)) {
    return 'Accept Request';
  } else if (RequestUtil.isPending(requestStatus) && RequestUtil.isMyRequest(requestAuthStatus)) {
    if (RequestUtil.iAmRequester(requestAuthStatus)) {
      return 'Mark as Received';
    } else {
      return 'Mark as Delivered';
    }
  } else if (RequestUtil.isCompleted(requestStatus) && RequestUtil.isMyRequest(requestAuthStatus)) {
    return 'Share Feedback';
  }
}
// endregion

const RequestDetail = ({ app, classes, data, onEditClicked, disableNext, onTimeSlotSelected,
                         selectedTimeSlot, onClickBack, onSubmit, userData, onCancelClicked }) => {

  // for null-safety
  const userInfo = userData || {};
  const isMyRequest = RequestUtil.isMyRequest(data.authStatus);
  const iAmRequester = RequestUtil.iAmRequester(data.authStatus);
  const iAmVolunteer = RequestUtil.iAmVolunteer(data.authStatus);
  const isExpired = RequestUtil.isExpired(data.status);
  const isOpen = RequestUtil.isOpen(data.status);
  const isPending = RequestUtil.isPending(data.status);
  const isCompleted = RequestUtil.isCompleted(data.status);

  useEffect(() => {
    app.postEvent(AppEvents.UPDATE_DATA, {
      [AppDataKey.SHOW_MY_REQUEST_ONLY]: isMyRequest
    });
  }, []);

  // region render helper methods
  const getEditButton = (target) => {
    // only requester can edit open or expired request
    if ( iAmRequester && (isOpen || isExpired)) {
      return (<Button
        className={classes.edit}
        color="primary"
        onClick={onEditClicked(target)}
        startIcon={<IconEdit className={classes.icon18} />}>
        Edit
      </Button>);
    } else {
      return null;
    }
  };

  const renderDeliverySection = (deliveryTimeslots) => {
    return (<div>
      <Divider className={classes.section}/>
      <Typography variant="subtitle1">
        Delivery Dates and Times
      </Typography>
      <Typography variant="body1" className={classes.section}>
        Please select one delivery date and time that you are available for.
      </Typography>
      {deliveryTimeslots.map((dateSlots) => (
        <FormControl key={dateSlots.date} fullWidth>
          <FormLabel component={Typography}>
            {getDateString(dateSlots.date)}
          </FormLabel>
          <RadioGroup onChange={onTimeSlotSelected} value={selectedTimeSlot}>
            {dateSlots.timeslots.map((timeSlot) => (
              <FormControlLabel
                key={timeSlot.start}
                value={timeSlot.start}
                control={<Radio size="small" />}
                label={getTimeString(timeSlot)} />
            ))}
          </RadioGroup>
        </FormControl>
      ))}
    </div>);
  }

  const getMainButton = () => {
    const buttonText = getMainButtonText(data.status, data.authStatus);
    if (!!buttonText) {
      return <Button
        fullWidth
        variant="outlined"
        color="primary"
        disabled={disableNext}
        type="submit">
        {buttonText}
      </Button>
    } else {
      return null;
    }
  };
  // endregion

  return (
    <div>
      <BackLabel
        onClickBack={onClickBack}
        backLabel={isMyRequest ? LABEL_BACK_TO_MY_REQUEST : LABEL_BACK_TO_ALL_REQUEST} />

      <form onSubmit={onSubmit}>
        <Card className={classes.cardRoot}>
          <CardContent>
            {/* title */}
            <Typography className={clsx(classes.cardTitle, classes.noMargin)}>
              { RequestUtil.getTitle(iAmRequester, data.requesteeName) }
            </Typography>

            {/* status label */}
            <div className={classes.statusLabelWrapper}>
              { RequestUtil.renderRequestStatusLabel(data, classes) }
            </div>

            {/* delivery time */}
            <Grid
              alignItems="flex-start"
              justify="space-between"
              className={classes.section}
              container >
              <IconCalendar className={clsx(classes.deliveryDetailIcon, classes.icon12)}/>
              <div style={{ flexGrow: 1 }}>
                {
                  !!data.selectedTimeslot ?
                    RequestUtil.renderSelectedTimeLabel(
                      data.selectedTimeslot,
                      classes
                    ) : RequestUtil.renderTimeSummayLabel(
                      data.deliveryTimeslots,
                      classes,
                      isExpired
                    )
                }
              </div>
              {getEditButton(1)}
            </Grid>
            {
              isExpired ? <div className={classes.expireTextWrapper}>
                { RequestUtil.getExpireText(isExpired, classes) }
              </div> : null
            }

            {/* delivery address */}
            { isOpen || (isMyRequest && isPending) || isExpired ?
              <Grid
                alignItems="flex-start"
                className={classes.section}
                container >
                <IconLocation className={clsx(classes.deliveryDetailIcon, classes.icon12)} />
                <div>
                  <Typography variant="body1" className={classes.lineBreakText}>
                    { RequestUtil.getAddress(isPending, data.deliveryAddress) }
                  </Typography>
                  {isOpen && !isMyRequest ?
                    <Typography variant="body1" className={classes.helperText}>
                      The delivery address will be visible after you accept the request.
                    </Typography> : null}
                </div>
              </Grid> : null
            }

            {/* household count */}
            { isOpen || (isMyRequest && isPending) ?
              <Grid
                alignItems="flex-start"
                className={classes.section}
                container >
                <IconHouse className={clsx(classes.deliveryDetailIcon, classes.icon12)} />
                <Typography variant="body1">
                  { userInfo.requesterHouseholdSize } household { userInfo.requesterHouseholdSize === 1 ? "member" : "members"}
                </Typography>
              </Grid> : null
            }

            {/* who is delivering? */}
            { iAmRequester && (isPending || isCompleted) ?
              <Grid
                alignItems="flex-start"
                className={classes.section}
                container >
                <IconPerson className={clsx(classes.deliveryDetailIcon, classes.icon12)} />
                <Typography variant="body1">
                  {RequestUtil.getDliveryInfo(data.status, data.volunteerName)}
                </Typography>
              </Grid> : null
            }

            {/* the other person's phone */}
            { isMyRequest && isPending ?
              <Grid
                alignItems="flex-start"
                className={classes.section}
                container >
                <IconPhone className={clsx(classes.deliveryDetailIcon, classes.icon12)} />
                <Typography variant="body1">
                  <Link
                    href={"tel:" + userInfo.phone}
                    target="_blank"
                    className={classes.hyperlink}>
                    { userInfo.phone }
                  </Link>
                </Typography>
              </Grid> : null
            }

            {/* user story */}
            {
              (isOpen && !isMyRequest)
                || (isPending && iAmVolunteer) ?
              <Typography variant="body1">{userInfo.requesterStory}</Typography> : null
            }

            {/* special instruction label form */}
            { iAmRequester && (isOpen || isPending || isExpired) && !!data.specialInstruction ?
              <Grid
                alignItems="flex-start"
                className={classes.section}
                container >
                <Grid item style={{ marginRight: '11px' }} className={classes.icon12}>
                  <IconNote
                    className={clsx(classes.deliveryDetailIcon, classes.icon12)}/>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {data.specialInstruction}
                  </Typography>
                </Grid>
              </Grid> : null
            }

            <Divider />

            {/* delivery items */}
            <Grid justify="space-between" alignItems="flex-start" container>
              <Typography variant="subtitle1">
                { isMyRequest ?
                  ( iAmRequester ?
                      (isCompleted ? "You requested:" : "You are looking for:") :
                      (isCompleted ? "Groceries provided" : "Groceries to Provide")
                  ) : data.requesteeName + " requested:"
                }
              </Typography>

              {getEditButton(0)}
            </Grid>
            <div className={clsx({
              [classes.section]: true,
              [classes.itemsWrapper]: iAmRequester && (isExpired || isOpen)
            })}>
              {data.deliveryItems.map((item, index) => (
                <Typography variant="body1" key={item.name+index}>
                  {item.quantity} x {item.name}
                </Typography>
              ))}
            </div>
            {
              (isOpen && !isMyRequest) || (isPending && iAmVolunteer) ?
                <Typography variant="body1" className={classes.helperText}>
                  You can choose to purchase any number of items from this list.
                </Typography> : null
            }

            {/* special instructions section */}
            {
              !!data.specialInstruction
                && ((isOpen && !isMyRequest) || (isPending && iAmVolunteer))
                ? <div>
                    <Divider className={classes.section}/>
                    <Typography variant="subtitle1" className={classes.section}>
                      {data.requesteeName}'s special instructions:
                    </Typography>
                    <Typography variant="body1">{data.specialInstruction}</Typography>
                  </div> : null
            }

            {/* delivery timeslot candidates */}
            {
              isOpen && !isMyRequest
                ? renderDeliverySection(data.deliveryTimeslots) : null
            }
          </CardContent>

          <CardContent>
            { getMainButton() }

            { iAmVolunteer && isPending ?
              <Button
                className={classes.secondFullWidthButton}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onCancelClicked}>
                Cancel delivery
              </Button> : null
            }
          </CardContent>
        </Card>
      </form>
    </div>
  );
}

export default compose(
  withStyles(mergedStyles),
  withAppContext,
)(RequestDetail);