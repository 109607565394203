import * as React from "react";

function SvgIconLocation(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 20" fill="none" {...props}>
      <path
        d="M7 0C3.13 0 0 3.13 0 7c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C9.58 16.92 14 11.17 14 7c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIconLocation;
