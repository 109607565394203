import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ProfileContext } from "../../Profile/BaseProfileController";
import { CommonStyle } from "../../../constants/styles";
import {
  labelAcknowledge1Requester,
  labelAcknowledge1Volunteer,
  labelAcknowledge2Requester,
  labelAcknowledge2Volunteer
} from "../../../constants/profileConstants";

const AcknowledgementComponent = ({ classes, isVolunteer }) => {

  const handleChange = (context) => (event) => {
    context.update(event.target.name, event.target.checked);
  };

  return (
    <ProfileContext.Consumer>
      {(context) => (
        <div>
          <Typography className={classes.sectionTitle}>
            Acknowledgement
          </Typography>

          <FormGroup onChange={handleChange(context)}>
            <FormControlLabel
              style={{ alignItems: 'start', marginBottom: '10px' }}
              control={<Checkbox
                style={{ paddingTop: '2px' }}
                size='small'
                inputProps={{
                  'aria-checked': !!context.state.acknowledge1,
                }} />
              }
              key='acknowledge1'
              label={isVolunteer ? labelAcknowledge1Volunteer : labelAcknowledge1Requester}
              checked={!!context.state.acknowledge1}
              name="acknowledge1"
            />
          </FormGroup>

          <FormGroup onChange={handleChange(context)}>
            <FormControlLabel
              style={{ alignItems: 'start' }}
              control={<Checkbox
                style={{ paddingTop: '2px' }}
                size='small'
                inputProps={{
                  'aria-checked': !!context.state.acknowledge2
                }} />
              }
              key='acknowledge2'
              label={isVolunteer ? labelAcknowledge2Volunteer : labelAcknowledge2Requester}
              checked={!!context.state.acknowledge2}
              name="acknowledge2"
            />
          </FormGroup>
        </div>
      )}
    </ProfileContext.Consumer>
  );
}

export default withStyles(CommonStyle)(AcknowledgementComponent);