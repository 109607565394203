import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { withBasicAuthorization } from '../../Session';
import { withFirebase } from '../../Firebase';
import {AppContent, AppEvents, withAppContext} from "../../App";
import { roleVolunteer } from "../../../constants/profileConstants";
import RequestListUI from "./RequestListUI";
import { withRequestListStore } from "./WithRequestListStore";
import { RequestListEvent } from "../../../constants/requestConstants";
import { logError, Tag } from "../../Helper/ErrorLogger";

const startupTaskTotal = 2;
const componentKey = 'request-list';

const RequestListController = ({ store, app, firebase }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVolunteer, setIsVolunteer] = useState(false);
  let taskCompleteCount = 0;

  const onTaskCompleted = () => {
    taskCompleteCount++;
    setIsLoading(taskCompleteCount < startupTaskTotal);
  };

  const onEvent = (eventKey) => {
    if (eventKey === RequestListEvent.REFRESH_SUCCEEDED
      || eventKey === RequestListEvent.REFRESH_ERROR) {
      onTaskCompleted();
    }
  };

  useEffect(() => {
    firebase.trackEvent('request_hub');
    app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.Other);
    store.subscribe(componentKey, onEvent);

    setIsLoading(true);
    store.refreshOrders();

    firebase.userRole(firebase.auth.currentUser.uid)
      .once('value', snapshot => {
        onTaskCompleted();
        setIsVolunteer(snapshot.val() === roleVolunteer);
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_LIST, {
          ...error,
          networkCall: "getUserRole"
        });
      onTaskCompleted();
      });

    return () => {
      store.unsubscribe(componentKey);
    }
  }, []);

  return <RequestListUI
    isLoading={isLoading}
    userIsVolunteer={isVolunteer} />
};

export default compose(
  withBasicAuthorization,
  withFirebase,
  withAppContext,
  withRequestListStore,
)(RequestListController);