import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { CommonStyle } from '../../constants/styles';
import { OnBoardingStatus } from "./OnBoardingStatus";

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { logError, Tag } from "../Helper/ErrorLogger";

export const roleTypes = [
	{
		displayName: "I need help getting groceries",
    description: "The volunteers will be covering the cost and delivery of your groceries.",
		key: "Requestee"
	},
	{
		displayName: "I want to provide groceries ",
    description: "You are expected to cover the grocery and delivery costs.",
    key: "Volunteer"
	}
];

const RoleSelectStyle = {
  roleDesc: {
    margin: '6px 0 0 5px',
    color: '#5c5c5c',
    fontSize: '14px',
    lineHeight: '21px',
  }
};

class RoleSelectComponentBase extends Component {
	constructor(props) {
    super(props);
    this.styles = props.classes;
    this.state = {
      role: null,
    }
  }

  onSubmit = event => {
  	// prevents page from refreshing
  	event.preventDefault();
  	this.props.firebase.user(this.props.firebase.auth.currentUser.uid)
  		.update({
  			role: this.state.role.key
  		}).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.ONBOARD, {
          ...error,
          networkCall: "updateUserRole"
        });
      });
    this.props.requestNav(OnBoardingStatus.ROLE_SELECT + 1);
    // set user property to the role selected
    this.props.firebase.setUserProperties({'user_role' : this.state.role.key});
    this.props.firebase.trackEvent('user_role_selected', {'role': this.state.role.key}); 
  }

  render() {
  	const handleChange = (event) => {
      const newRole = roleTypes.find(candidate => candidate.key === event.target.value);
      this.setState({ 
        role: newRole
      });
	  };

    return (
      <form onSubmit={this.onSubmit}>
        <Card className={this.styles.cardRoot}>
          <CardContent>
            <Typography className={this.styles.cardTitle}>
              What are you looking for?
            </Typography>
            <Typography style={{ marginBottom: '16px' }}>
              Let us know if you are signing up to volunteer or request groceries. 
            </Typography>

		        <TextField
		          id="select-role"
		          select
		          fullWidth
		          label="Select an option"
		          value={this.state.role ? this.state.role.key : ''}
		          onChange={handleChange}
              data-testid="select-role-display"
              inputProps={{ "data-testid": "select-role" }}
		          variant="outlined">
		          {roleTypes.map((option) => (
		            <MenuItem key={option.key} value={option.key}>
		              {option.displayName}
		            </MenuItem>
		          ))}
		        </TextField>
            <Typography 
              className={this.styles.roleDesc}>
              { this.state.role ? this.state.role.description : '' }
            </Typography>
          </CardContent>
          <div className={this.styles.space100}/>
          <CardActions>
          	<Button 
	          	fullWidth 
              disabled={!this.state.role}
	          	variant="contained"
	          	color="primary"
	          	type="submit">
	            Next
	          </Button>
          </CardActions>
        </Card>
      </form>
    );
	}
}

const mergedStyle = { ...CommonStyle, ...RoleSelectStyle };
export default compose(
  withFirebase,
  withStyles(mergedStyle)
)(RoleSelectComponentBase);