import * as React from "react";

function SvgIconInfo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M6 .167A5.835 5.835 0 00.167 6 5.835 5.835 0 006 11.833 5.835 5.835 0 0011.833 6 5.835 5.835 0 006 .167zm.583 8.75H5.417v-3.5h1.166v3.5zm0-4.667H5.417V3.083h1.166V4.25z"
        fill="#5C5C5C"
      />
    </svg>
  );
}

export default SvgIconInfo;
