import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import clsx from "clsx";

import { CommonStyle } from '../../../constants/styles';
import { IconBasket, IconDelivery } from '../../../icons';
import * as RequestUtil from '../../../constants/requestConstants';
import { getRequestDetailPath } from '../../../constants/routes';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const RequestItemStyle = {
	requestItemWrapper: {
    border: '1px solid #E8E8E8',
		borderRadius: '4px',
    padding: '16px',
    margin: "16px 0 0",
    cursor: 'pointer',
  },
  requestIcon: {
  	margin: '9px 16px 0 0',
  	height: 22,
  	width: 30
  },
  deliveryInfo: {
		marginTop: '13px',
  },
  requestInfo: {
  	marginTop: '16px',
  },
  arrow: {
  	margin: '7px 0 0 5px',
  },
  requestTitle: {
  	textDecorationLine: 'underline',
  }
};
const mergedStyle = { ...CommonStyle, ...RequestItemStyle, ...RequestUtil.CommonRequestStyles };

function getItemCountMessage(count, requestAuthStatus, isCompleted) {
	const itemWord = count === 1 ? "item" : "items";
	return RequestUtil.iAmRequester(requestAuthStatus) ?
		"You requested "+count+" "+itemWord :
		count+" "+itemWord+(RequestUtil.iAmVolunteer(requestAuthStatus) && isCompleted ?
			// I delivered something for someone
			" delivered" : " needed"
		)
}

const RequestItem = ({ classes, history, request }) => {

	const onItemClicked = () => {
		history.push(getRequestDetailPath(request.requestKey));
	};

	if (request.status === undefined) {
		request.status = RequestUtil.RequestStatus.OPEN;
	}
	const isMyRequest = RequestUtil.isMyRequest(request.authStatus);
	const iAmRequester = RequestUtil.iAmRequester(request.authStatus);
	const isExpired = RequestUtil.isExpired(request.status);
	const isOpen = RequestUtil.isOpen(request.status);
	const isPending = RequestUtil.isPending(request.status);
	const isCompleted = RequestUtil.isCompleted(request.status);

	return <Grid
		alignItems="flex-start"
		className={classes.requestItemWrapper}
		onClick={onItemClicked}
		container>
		{ iAmRequester ?
			<IconBasket className={classes.requestIcon} /> :
			<IconDelivery className={classes.requestIcon} />
		}
		<Grid item xs>
			<Typography variant="h6" className={classes.requestTitle}>
				{ RequestUtil.getTitle(iAmRequester, request.requesteeName) }
			</Typography>

			{ RequestUtil.renderRequestStatusLabel(request, classes) }

			{
				iAmRequester && !isOpen && !isExpired ?
					<Typography
						variant="body1"
						className={classes.deliveryInfo}>
						{RequestUtil.getDliveryInfo(request.status, request.volunteerName)}
					</Typography> : null
			}

			<div className={classes.requestInfo}>
				{
					!!request.selectedTimeslot ?
						RequestUtil.renderSelectedTimeLabel(request.selectedTimeslot, classes) :
						RequestUtil.renderTimeSummayLabel(request.deliveryTimeslots, classes, isExpired)
				}
			</div>

			{ isMyRequest ? RequestUtil.getExpireText(isExpired, classes) : null }

			{/* Address is only shown in two request statuses: open (short) or pending (long). */}
			{
				isOpen || (isMyRequest && isPending) ?
					<Typography
						variant="body1"
						className={clsx(classes.requestInfo, classes.lineBreakText)}>
						{ RequestUtil.getAddress(isPending, request.deliveryAddress) }
					</Typography> : null
			}

			<Typography
				className={classes.requestInfo}
				variant="body1">
				{ getItemCountMessage(
					request.deliveryItems.length,
					request.authStatus,
					isCompleted
				) }
			</Typography>
		</Grid>
	</Grid>
}

export default compose(
	withStyles(mergedStyle),
	withRouter,
)(RequestItem);