import BasicStore from "../Helper/BasicStore";
import { AppEvents } from "../../constants/appConstant";

class AppStore extends BasicStore {
  appData = {};

  postEvent = (eventKey, data) => {
    if (eventKey === AppEvents.UPDATE_DATA) {
      this.appData = {
        ...this.appData,
        ...data
      };
    } else {
      this.notifySubscribers(eventKey, data);
    }
  };

  getData = (key) => this.appData[key];
}

export default AppStore;