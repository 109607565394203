import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { compose } from 'recompose';

import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import './assets/scss/style.scss';
import { AppContent, withAppContext } from '../App';
import { withFirebase } from '../Firebase';
import { AppEvents } from "../../constants/appConstant";
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
// Views
import Home from './views/Home';

class Landing extends Component {

  constructor(props) {
    super(props);
    this.childRef = React.createRef();
  }

  componentDidMount() {
    this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.Landing);
    document.body.classList.add('is-loaded')
    this.childRef.current.init();
    this.props.firebase.trackEvent('app_opened');  
  }

  render() {
    return (
      <ScrollReveal
        ref={this.childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          </Switch>
        )} />
    );
  }
}

export default compose(
  withAppContext,
  withFirebase
)(Landing);
