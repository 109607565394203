import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../../Firebase';
import { NewRequestStatus } from './index';
import { CommonStyle } from '../../../constants/styles';
import { IconAdd, IconDelete } from '../../../icons';
import { CommonRequestStyles } from '../../../constants/requestConstants';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";

const quantityCandidates = [1,2,3,4,5];

const ItemsStyle = {
  inputItem: {
    width: '56%',
  },
  inputQty: {
    width: '23%',
  },
  deleteButton: {
    marginRight: "18px",
    cursor: "pointer"
  },
};
const mergedStyle = { ...CommonStyle, ...CommonRequestStyles, ...ItemsStyle };

class ItemsComponentBase extends Component {
	constructor(props) {
    super(props);
    this.styles = props.classes;
    this.isEditMode = props.isEdit;
    this.state = {
      items: props.data.deliveryItems
    }
  }

  onNext = event => {
  	// prevents page from refreshing
  	event.preventDefault();
    this.props.updateRequestData("deliveryItems", this.state.items, () => {
      this.props.requestNav(NewRequestStatus.ITEMS + 1);
    });
    if (this.isEditMode) {
      this.props.firebase.trackEvent('update_requested_items');  
    } else {
      this.props.firebase.trackEvent('added_new_items', {'count': this.state.items.length});  
    }
  }

  updateDeliveryItem = (itemIndex) => (event) => {
    event.preventDefault();
    this.update(itemIndex, event.target.name, event.target.value)
  }

  onDeliveryItemAction = (itemIndex, key) => (event) => {
    event.preventDefault();
    this.update(itemIndex, key);
  }

  update(itemIndex, key, value) {
    const newItems = this.state.items;

    if (key === "name") {
      newItems[itemIndex].name = value;
    } else if (key === "quantity") {
      newItems[itemIndex].quantity = Number(value);
      this.props.firebase.trackEvent('added_item_quantity');  
    } else if (key === "delete") {
      newItems.splice(itemIndex, 1);
      this.props.firebase.trackEvent('deleted_item');  
    } else if (key === "add") {
      newItems.push({
        name: '',
        quantity: ''
      })
      this.props.firebase.trackEvent('added_item');  
    }

    this.setState({
      items: newItems
    });

    this.props.onRequestDataChanged();
  }

  areItemsInvalid() {
    return this.state.items.length === 0 ||
      this.state.items.some(val => 
        val.name === '' || val.quantity === ''
      );
  }

  render() {
    return (
      <form onSubmit={this.onNext}>
        <Card className={this.styles.cardRoot}>
          <CardContent>
          	<Typography className={this.styles.cardTitle}>
              New request
            </Typography>

            <Typography style={{ marginBottom: this.state.items.length > 0 ? '16px' : '0' }}>
              Volunteers will be covering the costs of your groceries, we recommend having a maximum number of 10 items.
            </Typography>

		        {this.state.items.map((item, index) => (
              <div key={"item"+index} >
                { (index > 0) ? <Divider /> : "" }
                <Grid 
                  container
                  justify="space-between"
                  >
                  <TextField
                    id={"name-"+index}
                    name="name"
                    label="Item"
                    className={this.styles.inputItem}
                    value={item.name}
                    onChange={this.updateDeliveryItem(index)}
                    variant="outlined" />

                  <FormControl 
                    className={this.styles.inputQty}
                    variant="outlined">
                    <InputLabel id="quantity-label">Qty</InputLabel>
                    <Select
                      labelId="quantity-label"
                      id={"quantity-"+index}
                      name="quantity"
                      value={item.quantity}
                      label="Qty"
                      onChange={this.updateDeliveryItem(index)}>
                      {quantityCandidates.map((quantity) => (
                        <MenuItem key={quantity} value={quantity}>   {quantity}   </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <IconDelete 
                    className={this.styles.deleteButton} 
                    onClick={this.onDeliveryItemAction(index, "delete")} />
                </Grid>
              </div>
            ))}

            <Button 
              color="primary"
              name="add"
              onClick={this.onDeliveryItemAction(0, "add")}
              style={{ marginTop: '10px' }}
              startIcon={<IconAdd />} >
              New Grocery Item
            </Button>
          </CardContent>
          <div className={this.styles.space100}/>
          <CardActions>
            {
              this.isEditMode ? (<CardActionArea>
                <Button 
                  fullWidth 
                  disabled={this.areItemsInvalid()}
                  variant="contained"
                  color="primary"
                  type="submit">
                  Save Changes
                </Button>
                <Button 
                  fullWidth 
                  variant="outlined"
                  style={{ margin: "16px 0 0" }}
                  onClick={this.props.cancel}
                  color="primary">
                  Cancel Editing
                </Button>
              </CardActionArea>) : <Button 
                fullWidth 
                disabled={this.areItemsInvalid()}
                variant="contained"
                color="primary"
                type="submit">
                Add Delivery Details
              </Button>
            }
          </CardActions>
        </Card>
      </form>
    );
	}
}

export default compose(withFirebase, withStyles(mergedStyle))(ItemsComponentBase);