import React, { Component } from 'react';

import { AuthBaseStyles } from "../../../constants/authConstants";
import { ProfileContext } from '../BaseProfileController';
import * as ProfileUtil from '../../../constants/profileConstants';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

class RequesterDetailComponent extends Component {
	handleChange = (context) => (event) => {
    const key = event.target.name;
    const val = event.target.value;

    if (key === "supportRequest") {
      const index = Number(val);
      const targetArray = context.state[key];
      targetArray[index] = event.target.checked;
      context.update(key, targetArray);
    } else {
      context.update(key, val);
    }
  };

	constructor(props) {
    super(props);
    this.styles = props.classes;
  }

  render() {
  	return (
  		<ProfileContext.Consumer>
  			{(context) => (
  				<div>
            <Typography className={this.styles.sectionTitle}>
              How has COVID-19 impacted you? (optional)
            </Typography>
            <Typography>
              Share with volunteers on how COVID has impacted you and your household. 
            </Typography>
            <TextField
              id="myStory"
              name="myStory"
              label={ProfileUtil.labelStory}
              value={context.state.myStory || ''}
              fullWidth
              multiline
              rows={4}
              InputLabelProps={{ shrink: true }}
              onChange={this.handleChange(context)}
              className={this.styles.topMarginLarge}
              variant="outlined" />

            <TextField
              select
              fullWidth
              id="householdSize"
              name="householdSize"
              label={ProfileUtil.labelHouseSize}
              value={Number(context.state.householdSize)}
              onChange={this.handleChange(context)}
              className={this.styles.topMarginLarge}
              data-testid="select-household-display"
              inputProps={{ "data-testid": "select-household" }}
              variant="outlined">
              {[...Array(15).keys()].map(index => (
                  <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
                ))}
            </TextField>
          
			  		<FormControl fullWidth>
			        <FormLabel className={this.styles.sectionTitle} component={Typography}>
			          What kind of assistance do you need?
			        </FormLabel>
			        <FormGroup onChange={this.handleChange(context)}>
			          {ProfileUtil.supportTypesRequestee.map((option, index) => (
                  <FormControlLabel
                    control={<Checkbox
                      size='small'
                      inputProps={{ 
                        'aria-checked': context.state.supportRequest[index] || false 
                      }} />
                    }
                    key={index}
                    label={option}
                    value={index}
                    checked={context.state.supportRequest[index] || false}
                    name="supportRequest"
                  />
                ))}
			        </FormGroup>
			      </FormControl>
  				</div>
  			)}
	    </ProfileContext.Consumer>
  	);
  }
}

export default withStyles(AuthBaseStyles)(RequesterDetailComponent);
