import * as React from "react";

function SvgIconBasket(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31 27" fill="none" {...props}>
      <path
        d="M22.664 10.375l-6.023-9.02C16.38.97 15.94.777 15.5.777c-.44 0-.88.193-1.141.592l-6.023 9.006H1.75c-.756 0-1.375.619-1.375 1.375 0 .124.014.248.055.371l3.492 12.746a2.734 2.734 0 002.64 2.008h17.875a2.763 2.763 0 002.654-2.008l3.493-12.746.041-.371c0-.756-.619-1.375-1.375-1.375h-6.586zm-11.289 0l4.125-6.05 4.125 6.05h-8.25zm4.125 11a2.758 2.758 0 01-2.75-2.75 2.758 2.758 0 012.75-2.75 2.758 2.758 0 012.75 2.75 2.758 2.758 0 01-2.75 2.75z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIconBasket;
