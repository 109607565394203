import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
 
import { withBasicAuthorization } from '../../Session';
import { withFirebase } from '../../Firebase';
import * as RequestUtil from '../../../constants/requestConstants';
import RequestFeedbackModal from './views/RequestFeedbackModal';
import RequestCancelModal from './views/RequestCancelModal';
import RequestDetailComponent from './views/RequestDetailComponent';
import RequestConnectModal from './views/RequestConnectModal';
import {
  AppContent,
  AppEvents,
} from "../../../constants/appConstant";
import { withAppContext } from "../../App";
import { REQUESTS } from "../../../constants/routes";
import { NewRequestStatus } from "../New";
import { AuthStatus } from "../../../constants/requestConstants";
import { logError, Tag } from "../../Helper/ErrorLogger";

class RequestDetail extends Component {

  state = {
    selectedSlot: 0,
    isLoading: true,
    isFeedbackModalShowing: false,
    isCancellationModalShowing: false,
    isConnectModalShowing: false,
  };

  userData = {};

  componentDidMount() {
    this.props.firebase.trackEvent('show_request_detail');
    this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.Other);

    this.orderId = this.props.match.params.id;
    this.refresh();
  }

  refresh() {
    this.reset();
    this.props.firebase.order(this.orderId)
      .once('value', snapshot => {
        this.requestData = snapshot.val();
        RequestUtil.formatRequest(this.requestData, this.props.firebase.auth.currentUser.uid);

        this.iAmRequester = this.requestData.authStatus === AuthStatus.REQUESTER;

        this.getAdditionalRefreshTasks().forEach(task => task());
        this.onTaskFinished();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "getRequestDetail",
          orderId: this.orderId
        });
      });
  }

  // region Refresh helper
  reset() {
    this.setState({
      selectedSlot: 0,
      isLoading: true,
    });
    this.startupTaskTotal = 0;
    this.taskCompleteCount = 0;
  }

  onTaskFinished() {
    if (this.taskCompleteCount >= this.startupTaskTotal) {
      this.setState({
        isLoading: false
      });
    }
  }

  taskFetchRequesterInfo = () => {
    this.props.firebase.user(this.requestData.requestee)
      .once('value', snapshot => {
        const requesterProfile = snapshot.val();
        this.userData.requesterStory = requesterProfile.myStory;
        this.userData.requesterHouseholdSize = requesterProfile.householdSize;

        if (!this.iAmRequester) {
          this.userData.phone = requesterProfile.phone;
        }

        this.taskCompleteCount++;
        this.onTaskFinished();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "getRequestorProfile",
          requestorId: this.requestData.requestee,
          orderId: this.orderId
        });
      });
  };

  taskFetchVolunteerInfo = () => {
    this.props.firebase.user(this.volunteerId)
      .once('value', snapshot => {
        this.userData.phone = snapshot.val().phone;
        this.taskCompleteCount++;
        this.onTaskFinished();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "getVolunteerProfile",
          volunteerId: this.volunteerId,
          orderId: this.orderId
        });
      });
  };

  taskFetchVolunteerPref = () => {
    this.props.firebase.user(this.volunteerId)
      .once('value', snapshot => {
        this.neverShowConnectModal = snapshot.val().neverShowConnectModal;
        this.taskCompleteCount++;
        this.onTaskFinished();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "getVolunteerPref",
          volunteerId: this.volunteerId,
          orderId: this.orderId
        });
      });
  };

  getAdditionalRefreshTasks() {
    // decide whether more network call is needed
    const tasks = [];
    const status = this.requestData.status;

    if (status === RequestUtil.RequestStatus.OPEN
      || status === RequestUtil.RequestStatus.PENDING) {
      // need to fetch requester profile for phone, household size, and story
      tasks.push(this.taskFetchRequesterInfo);
    }

    if (status === RequestUtil.RequestStatus.PENDING && this.iAmRequester) {
      this.volunteerId = this.requestData.volunteer;
      tasks.push(this.taskFetchVolunteerInfo);
    } else if (status === RequestUtil.RequestStatus.OPEN && !this.iAmRequester) {
      this.volunteerId = this.props.firebase.auth.currentUser.uid;
      tasks.push(this.taskFetchVolunteerPref);
    }

    this.startupTaskTotal = tasks.length;
    return tasks;
  }
  // endregion

  onSubmit = event => {
    this.setState({
      isLoading: true
    });

    event.preventDefault();
    const orderDetail = {};
    const newState = {};
    const status = this.requestData.status;

    if (status === RequestUtil.RequestStatus.OPEN) {
      this.onRequestAccepted(orderDetail, newState);
    } else if (status === RequestUtil.RequestStatus.PENDING) {
      this.onRequestCompleted(orderDetail, newState);
    } else if (status === RequestUtil.RequestStatus.COMPLETED) {
      this.onShareFeedbackClicked(newState);
    }

    if (Object.keys(orderDetail).length === 0) {
      // nothing to update on server side
      newState.isLoading = false;
      this.setState(newState);
    } else {
      this.props.firebase.order(this.orderId)
        .update(orderDetail, error => {
          if (error) {
            logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
              ...error,
              networkCall: "updateOrder",
              orderId: this.orderId
            });
          }

          this.setState(newState);
          this.refresh();
        });
    }
  };

  // region Submit helper
  onRequestAccepted(orderDetail, newState) {
    orderDetail.selectedTimeslot = this.state.selectedSlot;
    orderDetail.status = RequestUtil.RequestStatus.PENDING;
    orderDetail.volunteer = this.props.firebase.auth.currentUser.uid;
    orderDetail.volunteerName = this.props.firebase.auth.currentUser.displayName;

    if (!this.neverShowConnectModal) {
      newState.isConnectModalShowing = true;
    }

    this.props.firebase.trackEvent('request_accepted');
  }

  onRequestCompleted(orderDetail, newState) {
    orderDetail.status = RequestUtil.RequestStatus.COMPLETED;
    newState.isFeedbackModalShowing = true;

    if (this.iAmRequester) {
      this.props.firebase.trackEvent('request_received');
    } else {
      this.props.firebase.trackEvent('request_delivered');
    }
  }

  onShareFeedbackClicked(newState) {
    newState.isFeedbackModalShowing = true;
    this.props.firebase.trackEvent('request_feedback');
  }
  // endregion

  onSlotSelected = event => {
    this.setState({
      selectedSlot: Number(event.target.value)
    });
  };

  closeModal = (modalControl) => () => {
    this.setState({
      [modalControl]: false
    });
  };

  onCancelRequest = () => {
    this.setState({
      isCancellationModalShowing: true
    });
  };

  onCloseConnectModal = (neverShowAgain) => {
    if (neverShowAgain) {
      this.props.firebase.user(this.volunteerId)
        .update({
          neverShowConnectModal: true
        }).catch(error => {
          logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
            ...error,
            networkCall: "neverShowConnectModalAgain",
          });
        });
    }
    this.closeModal("isConnectModalShowing")();
  };

  submitCancellation = (reason) => {
    this.closeModal("isCancellationModalShowing")();

    // then reset order detail
    this.props.firebase.order(this.orderId)
      .update({
        status: RequestUtil.RequestStatus.OPEN,
        volunteer: null,
        volunteerName: null,
        selectedTimeslot: null
      }).then(() => {
        this.refresh();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "cancelOrder",
          orderId: this.orderId
        });
      });

    // upload cancellation reason
    this.props.firebase.cancelReason()
      .push({
        order: this.orderId,
        volunteer: this.requestData.volunteer,
        reason: reason
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_DETAIL, {
          ...error,
          networkCall: "updateOrderCancelReason",
          orderId: this.orderId
        });
      });
  };

  onEdit = (target) => () => {
    this.props.history.push('/request/'+this.orderId+'/edit?index='+target);

    let section;
    if (target === NewRequestStatus.ITEMS) {
      section = 'items';
    } else if (target === NewRequestStatus.DELIVERY_DETAIL) {
      section = 'delivery_details';
    } else {
      section = 'unknown';
    }
    this.props.firebase.trackEvent('edit_my_request', {'section': section});
  };

  backToRequest = () => {
    this.props.history.push(REQUESTS);
    this.props.firebase.trackEvent('back_to_requests');
  };

  render() {
    return (
      <div>
        {
          this.state.isLoading ?
          <CircularProgress /> :
          <RequestDetailComponent
            data={this.requestData}
            userData={this.userData}
            disableNext={
              this.requestData.status === RequestUtil.RequestStatus.OPEN
                && !this.iAmRequester
                && this.state.selectedSlot === 0
            }
            onEditClicked={this.onEdit}
            onCancelClicked={this.onCancelRequest}
            onTimeSlotSelected={this.onSlotSelected}
            selectedTimeSlot={this.state.selectedSlot}
            onClickBack={this.backToRequest}
            onSubmit={this.onSubmit} />
        }
        <RequestFeedbackModal 
          isModalShowing={this.state.isFeedbackModalShowing}
          closeModal={this.closeModal("isFeedbackModalShowing")} />
        <RequestCancelModal 
          isModalShowing={this.state.isCancellationModalShowing}
          submitCancellation={this.submitCancellation}
          closeModal={this.closeModal("isCancellationModalShowing")} />
        {
          this.requestData ? 
          <RequestConnectModal 
            isModalShowing={this.state.isConnectModalShowing}
            otherName={
              this.iAmRequester ? this.requestData.volunteerName : this.requestData.requesteeName
            }
            otherPhone={this.userData.phone}
            onClose={this.onCloseConnectModal} /> : null
        }
      </div>
    );
  }
}

export default compose( 
  withBasicAuthorization, 
  withFirebase,
  withRouter,
  withAppContext,
)(RequestDetail);