import { formatRequest, RequestStatus, RequestListEvent } from "../../../constants/requestConstants";
import BasicStore from "../../Helper/BasicStore";
import { logError, Tag } from "../../Helper/ErrorLogger";

// region Helper functions
function formatRequestsFromServer(candidate, userId) {
  let requestsFromServer;
  if (candidate) {
    requestsFromServer = candidate;
  } else {
    requestsFromServer = [];
  }
  const requests = Object.keys(requestsFromServer).map(key => ({
    requestKey: key,
    ...requestsFromServer[key]
  }));
  requests.forEach(each => formatRequest(each, userId));
  return requests
}

function composeCommunityList(requestsCandidate, myUserId) {
  // remove user's own request and expired request
  const requests = requestsCandidate.filter(request =>
    request.status === RequestStatus.OPEN
      && request.volunteer !== myUserId
      && request.requestee !== myUserId
  );

  // now sort based on following rules
  // 1. open requests first, then pending/completed request
  // 2. within each group, sort by earliest delivery date
  // requests.sort((a,b) => {
  //   let result = a.status - b.status;
  //
  //   if (result === RequestStatus.COMPLETED - RequestStatus.PENDING
  //     || result === RequestStatus.PENDING - RequestStatus.COMPLETED) {
  //     // treat pending and completed status as equal
  //     result = 0;
  //   }
  //
  //   if (result === 0) {
  //     result = a.deliveryTimeslots[0].date - b.deliveryTimeslots[0].date;
  //   }
  //
  //   return result;
  // });

  return requests;
}

function composeMyList(requestsCandidate, myUserId) {
  // filter only my requests
  const requests = requestsCandidate.filter(request =>
    request.requestee === myUserId || request.volunteer === myUserId
  );

  // The sorting logic is
  // 1. expired request -> open or pending request -> completed
  // 2. within the same status, sort by earliest delivery date
  requests.sort((a,b) => {
    if (a.status === b.status) {
      return a.deliveryTimeslots[0].date - b.deliveryTimeslots[0].date;
    } else if (a.status === RequestStatus.EXPIRED || b.status === RequestStatus.EXPIRED) {
      // expired is put at the top
      return a.status === RequestStatus.EXPIRED ? -1 : 1;
    } else if (a.status === RequestStatus.COMPLETED || b.status === RequestStatus.COMPLETED) {
      // completed is put at the bottom
      return a.status === RequestStatus.COMPLETED ? 1 : -1;
    } else {
      return a.deliveryTimeslots[0].date - b.deliveryTimeslots[0].date;
    }
  });

  return requests;
}
// endregion

class RequestListStore extends BasicStore {
  fullList = [];
  myList = [];

  constructor(firebase) {
    super();
    this.firebase = firebase;
  }

  // region refresh methods
  refreshOrders = () => {
    this.firebase.orders()
      .once('value', snapshot => {
        const uid = this.firebase.auth.currentUser.uid;
        const requestsCandidate = formatRequestsFromServer(snapshot.val(), uid);
        this.fullList = composeCommunityList(requestsCandidate, uid);
        this.myList = composeMyList(requestsCandidate, uid);
        this.notifySubscribers(RequestListEvent.REFRESH_SUCCEEDED, null);
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_LIST, {
          ...error,
          networkCall: "getOrders"
        });
        this.notifySubscribers(RequestListEvent.REFRESH_ERROR, null);
      });
  };
  // endregion
}

export default RequestListStore;