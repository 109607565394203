import * as React from "react";

function SvgIconPhone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M10.672 7.972a6.675 6.675 0 01-2.059-.327.57.57 0 00-.589.14l-.916 1.15C5.458 8.146 3.912 6.658 3.09 4.95l1.138-.968a.595.595 0 00.14-.595 6.505 6.505 0 01-.327-2.06.583.583 0 00-.578-.577H1.445C1.13.75.75.89.75 1.327c0 5.42 4.51 9.923 9.922 9.923.415 0 .578-.367.578-.688V8.549a.583.583 0 00-.578-.577z"
        fill="#4A4A4A"
      />
    </svg>
  );
}

export default SvgIconPhone;
