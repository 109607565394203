import React, { useEffect, useState } from "react";
import {BrowserRouter, Route} from "react-router-dom";
import { compose } from "recompose";

import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { AppContext } from "./WithAppContext";
import { AuthUserContext, withAuthentication } from "../Session";
import { CommonStyle } from "../../constants/styles";
import * as ROUTES from "../../constants/routes";
import LandingPage from "../Landing";
import AppHeader from "../Header";
import OnBoardingPage from "../OnBoarding";
import RequestList, { RequestListStore, RequestListStoreContext } from "../Request/List";
import NewRequestPage from "../Request/New";
import ProfilePage from "../Profile";
import RequestDetail from "../Request/Detail";
import EditRequestPage from "../Request/Edit";
import TermsConditions from "../Landing/TermsConditions";
import PrivacyPolicy from "../Landing/PrivacyPolicy";
import NavCancelModal from "../Modal/NavCancelModal";
import SignOutPage from "../SignOut";
import { IS_DEV } from "../../constants/appConstant";
import { UserDataDeletionPolicy } from "../Policies";
import { FirebaseContext } from "../Firebase";
import AppStore from "./AppStore";

const App = ({ classes }) => {
  const [appStore] = useState(new AppStore());

  useEffect(() => {
    console.log('Loading v'+process.env.REACT_APP_VERSION+' in '+process.env.REACT_APP_STAGE);
  }, []);

  return <AppContext.Provider value={appStore}>
    <BrowserRouter>
      <div style={{ backgroundColor: '#E5E5E5' }}>
        <AuthUserContext.Consumer>
          { authUser => <AppHeader isLoggedIn={!!authUser} /> }
        </AuthUserContext.Consumer>
        <Route exact path={ROUTES.LANDING} component={LandingPage} />

        <Container maxWidth="sm">
          <Route exact path={ROUTES.ONBOARDING} component={OnBoardingPage} />
          <FirebaseContext.Consumer>
            { firebase => <RequestListStoreContext.Provider value={new RequestListStore(firebase)}>
              <Route exact path={ROUTES.REQUESTS} component={RequestList} />
            </RequestListStoreContext.Provider> }
          </FirebaseContext.Consumer>
          <Route exact path={ROUTES.NEW_REQUEST} component={NewRequestPage} />
          <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
          <Route exact path={ROUTES.REQUEST} component={RequestDetail} />
          <Route exact path={ROUTES.REQUEST_EDIT} component={EditRequestPage} />
          <Route exact path={ROUTES.TERMS} component={TermsConditions} />
          <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={ROUTES.USER_DATA_DELETION_POLICY} component={UserDataDeletionPolicy} />
          <Route exact path={ROUTES.LOG_OUT} component={SignOutPage} />
        </Container>

        <div className={classes.space100}/>
        <NavCancelModal />

        {/* for debug only */}
        { IS_DEV ? <Typography
          style={{position: 'fixed', bottom: '5px', left: '5px', fontWeight: 'bold'}}>
          {'v'+process.env.REACT_APP_VERSION}
        </Typography> : null }
      </div>
    </BrowserRouter>
  </AppContext.Provider>
}

export default compose(withAuthentication, withStyles(CommonStyle))(App);