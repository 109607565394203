import React, {useEffect} from 'react';
import { compose } from "recompose";

import CircularProgress from "@material-ui/core/CircularProgress";

import { withFirebase } from '../Firebase';
import { withBasicAuthorization } from "../Session";
import { AppContent, AppEvents, withAppContext } from "../App";
import { logError, Tag } from "../Helper/ErrorLogger";


const SignOutPage = ({ firebase, app }) => {

  useEffect(() => {
    app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.OTHER_NO_AUTH);
    firebase.doSignOut()
      .catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.AUTH, {
          ...error,
          networkCall: "signOut"
        });
      });
  });

  return (
    <CircularProgress />
  )
};

export default compose(
  withFirebase,
  withAppContext,
  withBasicAuthorization,
)(SignOutPage);