import * as React from "react";

function SvgIconDelete(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" {...props}>
      <path
        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zM13 1h-2.5L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgIconDelete;
