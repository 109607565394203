import React, { Component } from 'react';

import * as Constant from '../../../constants/profileConstants';
import { ProfileContext } from '../BaseProfileController';
import { AuthBaseStyles } from "../../../constants/authConstants";

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

class GeneralInfoComponent extends Component {

	handleChange = (context) => (event) => {
    const key = event.target.name;
    const val = event.target.value;

    if (key === "phone") {
    	context.update(
    		"errormessage", 
    		!!Number(val) ? '' : "Please enter a valid phone number"
    	);
    } 
    context.update(key, val);
  };

  constructor(props) {
    super(props);
    this.styles = props.classes;
  }

	render() {
		const isVolunteer = this.props.isVolunteer;
    return (
      <div>
      	<ProfileContext.Consumer>
	      	{(context) => (
	      		<div data-testid="testWrapper">
		      		<TextField 
			          fullWidth
			          id="name" 
			          name="fullName"
			          label={Constant.labelName}
			          value={context.state.fullName || ''}
			          InputLabelProps={{ shrink: true }}
			          onChange={this.handleChange(context)}
			          variant="outlined" />

			        <Typography className={this.styles.sectionTitle}>
			        	{
			        		isVolunteer ? Constant.titleLocationVolunteer : Constant.titleNeighbourhoodRequester
			        	}
			        </Typography>
			        <Typography>
				        {
				        	isVolunteer ? Constant.msgLocationVolunteer : Constant.msgNeighbourhoodRequester
				        }
			        </Typography>
			        <TextField
			          fullWidth
			          id="city"
			          name="city"
			          label={Constant.labelCity}
			          value={context.state.city || ''}
			          InputLabelProps={{ shrink: true }}
			          onChange={this.handleChange(context)}
			          className={this.styles.topMarginLarge}
			          variant="outlined" />
			        <TextField
			        	select
			          fullWidth
			          id="province"
			          name="province"
			          label={Constant.labelProvince}
			          value={context.state.province}
			          onChange={this.handleChange(context)}
			          className={this.styles.topMarginLarge}
	              data-testid="select-province-display"
	              inputProps={{ "data-testid": "select-province" }}
			          variant="outlined">
			          {Constant.provinces.map((province) => (
			            <MenuItem key={province} value={province}>
			              { province }
			            </MenuItem>
			          ))}
			        </TextField>
              {
                isVolunteer ? null : <TextField
                  fullWidth
                  id="postalCode"
                  name="postalCode"
                  label={Constant.labelPostal}
                  value={context.state.postalCode || ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={this.handleChange(context)}
                  className={this.styles.topMarginLarge}
                  variant="outlined" />
              }

              {
                isVolunteer ? null : <div>
                  <Typography className={this.styles.sectionTitle}>
                    { Constant.titleLocationRequester }
                  </Typography>
                  <Typography>
                    { Constant.msgLocationRequester }
                  </Typography>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    value={context.state.address || ''}
                    label={Constant.labelAddr}
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleChange(context)}
                    className={this.styles.topMarginLarge}
                    variant="outlined" />
                </div>
              }

			        <Typography className={this.styles.sectionTitle}>
			        	Contact details
			        </Typography>
			        <Typography>
				        {
				        	isVolunteer ? Constant.msgPhoneVolunteer : Constant.msgPhoneRequester
				        }
			        </Typography>
			        <TextField
			          fullWidth
			          id="phoneNumber"
			          name="phone"
			          label={Constant.labelPhone}
			          value={context.state.phone || ''}
			          InputLabelProps={{ shrink: true }}
			          error={context.state.errormessage !== ''}
			          helperText={context.state.errormessage}
			          onChange={this.handleChange(context)}
			          className={this.styles.topMarginLarge}
			          variant="outlined" />
			        </div>
	      	)}
        </ProfileContext.Consumer>
     </div>
    );
  }

}

export default withStyles(AuthBaseStyles)(GeneralInfoComponent);