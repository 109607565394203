import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

const ToastComponent = ({ level, errorMsg, successMsg, onClose }) => {
  const [msg, setMsg] = React.useState('');
  const [severity, setSeverity] = React.useState(undefined);

  // this is to prevent UI change right before dismissing the snackbar
  useEffect(() => {
    if (level === 'success') {
      setMsg(successMsg);
      setSeverity('success');
    } else if (level === 'error') {
      setMsg(errorMsg);
      setSeverity('error');
    }
  }, [level, successMsg, errorMsg])

  return (
    <Snackbar
      open={!!level}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}>
      <MuiAlert severity={severity} onClose={onClose}>
        { msg }
      </MuiAlert>
    </Snackbar>
  )
};

export default ToastComponent;
