import * as React from "react";

function SvgIconBack(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="none" {...props}>
      <path
        d="M7.41 1.41L6 0 0 6l6 6 1.41-1.41L2.83 6l4.58-4.59z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgIconBack;
