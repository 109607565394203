export const LANDING = '/';
export const ONBOARDING = '/onboard';
export const LOG_OUT = '/logout';
export const PROFILE = '/profile';
export const NEW_REQUEST = '/new-request';
export const REQUESTS = '/requests';
export const REQUEST = '/request/:id';
export const REQUEST_EDIT = '/request/:id/edit';
export const TERMS = '/terms';
export const PRIVACY_POLICY = '/privacy';
export const USER_DATA_DELETION_POLICY = '/data-deletion';

export const getRequestDetailPath = (requestId) => {
	return '/request/'+requestId;
};

export const FORM_GENERAL = "https://forms.gle/mFU9tF7tCjBCnUxq9";
export const FORM_DELIVERY_FEEDBACK = "https://forms.gle/S7y7tJVimWUAQ4Sf6";