import { IS_PROD } from "../../constants/appConstant";

export function logError(userId, tag, error) {
  const bodyJson = {
    appVersion: process.env.REACT_APP_VERSION,
    userId: userId,
    tag: tag,
    error: error
  };
  console.log(bodyJson);
  // for local testing, use http://localhost:5001/wecare-9aa48/us-central1/logErrorDev
  fetch(
    IS_PROD ? 
      "https://us-central1-wecareprod.cloudfunctions.net/logErrorProd" :
      "https://us-central1-wecare-9aa48.cloudfunctions.net/logErrorDev",
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyJson)
    }
  ).catch(error => {
    console.log(error);
  });
}

export const Tag = {
  HEADER: "header",
  REQUEST_DETAIL: "request-detail",
  REQUEST_EDIT: "request-edit",
  REQUEST_NEW: "request-new",
  REQUEST_LIST: "request-list",
  AUTH: "auth",
  PROFILE: "profile",
  ONBOARD: "onboard"
};