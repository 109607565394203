import React, { Component } from 'react';

import { withFirebase } from '../../Firebase';
import { IconFacebook } from '../../../icons';

import Button from '@material-ui/core/Button';

class AuthFacebook extends Component {

  onFBClicked = () => {
    this.props.handleSubmitProvider(this.props.firebase.doSignInWithFacebook);
    this.props.firebase.trackEvent('sign_in_with_fb');
  };

  render() {
    return (
      <Button 
        fullWidth 
        variant="outlined"
        color="primary" 
        className={this.props.className}
        onClick={this.onFBClicked}
        startIcon={<IconFacebook />} >
        Continue with Facebook
      </Button>
    );
  }
}

export default withFirebase(AuthFacebook);