import React, { Component } from 'react';
import * as ProfileUtil from '../../constants/profileConstants';
import { logError, Tag } from "../Helper/ErrorLogger";

export const ProfileContext = React.createContext();

export default class BaseProfileController extends Component {

	constructor(props) {
		super(props);
		this.state = {
      fullName: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      phone: '',
      householdSize: 1,
      supportRequest: [ false, false, false, false ],
      myStory: '',
      errormessage: '',
      acknowledge1: false,
      acknowledge2: false,
		};
    this.submitProfile = this.submitProfile.bind(this);
    this.getComponentName = this.getComponentName.bind(this);
    this.isInputInvalid = this.isInputInvalid.bind(this);
    this.onDataChanged = this.onDataChanged.bind(this);
    this.styles = props.classes;
	}

	onDataChanged() {
	  // default do nothing
  }

  getComponentName() {
	  return "base";
  }

  submitProfile(event) {
    event.preventDefault();
    this.props.firebase.auth.currentUser.updateProfile({
      displayName: this.state.fullName,
    });

    this.props.firebase.user(this.props.firebase.auth.currentUser.uid)
      .update({
        fullName: this.state.fullName,
        address: this.state.address,
        city: this.state.city,
        province: this.state.province,
        postalCode: this.state.postalCode,
        phone: this.state.phone,
        householdSize: this.state.householdSize,
        myStory: this.state.myStory,
        supportRequest: this.getSelectionForSubmit(this.state.supportRequest, ProfileUtil.supportTypesRequestee),
      }).then(val => {
        this.onSubmitSuccess();
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.PROFILE, {
          ...error,
          networkCall: "updateProfile",
          component: this.getComponentName()
        });
        this.onSubmitError();
      });
  }

  getSelectionForSubmit(userInput, candidates) {
    return userInput.map((val, index) => val ? candidates[index] : '')
                    .filter(val => val !== '');
  }

  onSubmitSuccess() {
    // default do nothing
  }

  onSubmitError() {
	  // default do nothing
  }
  
  isInputInvalid() {
    // household size field seems to have auto-error-check
    return !this.state.fullName
      || (!this.state.isVolunteer && !this.state.address)
      || (!this.state.isVolunteer && !this.state.postalCode)
      || !this.state.city
      || !this.state.province
      || !this.state.phone
      || this.state.errormessage !== ''
      || (!this.state.isVolunteer && this.state.supportRequest.every(val => val === false));
  };

	render() {
		return (
			<ProfileContext.Provider 
      	value={{   
      		state: this.state,
          update: (key, data) => {
          	this.setState({ [key]: data });
          	this.onDataChanged();
          }
        }}>
      	{this.renderContent()}
      </ProfileContext.Provider>
		)
	}

	renderContent() {
		return null;
	}
}
