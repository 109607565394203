import React, { Component } from 'react';

import { withFirebase } from '../../Firebase';
import { IconGoogle } from '../../../icons';

import Button from '@material-ui/core/Button';

class AuthGoogle extends Component {

  onGoogleClicked = () => {
    this.props.handleSubmitProvider(this.props.firebase.doSignInWithGoogle);
    this.props.firebase.trackEvent('sign_in_with_google');  
  };

  render() {
    return (
      <Button 
        fullWidth 
        variant="outlined"
        color="primary" 
        className={this.props.className}
        onClick={this.onGoogleClicked}
        startIcon={<IconGoogle />} >
        Continue with Google
      </Button>
    );
  }
}

export default withFirebase(AuthGoogle);