import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withBasicAuthorization } from '../../Session';
import { withFirebase } from '../../Firebase';
import ItemsComponent from '../New/ItemsComponent';
import DeliveryComponent from '../New/DeliveryComponent';
import { NewRequestStatus } from '../New';
import * as RequestUtil from '../../../constants/requestConstants';
import { getRequestDetailPath } from '../../../constants/routes';
import {AppContent, AppEvents, withAppContext} from '../../App';
import { logError, Tag } from "../../Helper/ErrorLogger";

class EditRequestComponent extends Component {
  requestNav = (navTargetId) => {
    if (this.uiInitiated) {
      const updateData = {};
      let isRequestValid = false;
      if (navTargetId === NewRequestStatus.DELIVERY_DETAIL) {
        // update delivery items
        updateData.deliveryItems = this.state.deliveryItems;
        isRequestValid = true;
      } else if (navTargetId === NewRequestStatus.COMPLETE) {
        updateData.deliveryTimeslots = this.state.deliveryTimeslots;
        updateData.specialInstruction = this.state.specialInstruction;
        isRequestValid = true;
      }

      if (isRequestValid) {
        this.props.firebase.order(this.orderId)
          .update(updateData)
          .then(val => {
            // exit without warning
            this.props.app.postEvent(AppEvents.ALLOW_NAV, true);
            this.props.history.push(getRequestDetailPath(this.orderId));
          }).catch(error => {
            logError(this.props.firebase.getUidSafe(), Tag.REQUEST_EDIT, {
              ...error,
              networkCall: "updateOrder",
              orderId: this.orderId
            });
          });
      }
    } else {
      const target = this.navComponents.find( candidate => candidate.id === navTargetId );
      if (target) {
        this.setState({
          mainPage: target.component,
        });
      } else {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_EDIT, {
          reason: 'Cannot find navigation target with ID '+navTargetId
        });
      }
      this.uiInitiated = true;
    }
  };

  updateRequestData = (key, data, callback) => {
    this.setState({
      [key]: data
    }, callback);
  };

  onRequestDataChanged = () => {
    this.props.app.postEvent(AppEvents.ALLOW_NAV, false);
  };

  getData = () => {
    return this.state;
  };

  cancel = () => {
    this.props.history.goBack();
  };

  constructor(props) {
    super(props);
    this.uiInitiated = false;
    this.state = {
      mainPage: '',
      deliveryTimeslots: [],
      deliveryItems: [],
      specialInstruction: ''
    };
  }

  componentDidMount() {
    this.orderId = this.props.match.params.id;
    this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.EditRequest);
    this.props.app.postEvent(AppEvents.ALLOW_NAV, true);
    this.props.firebase.order(this.orderId)
      .once('value', snapshot => {
        const data = snapshot.val();
        RequestUtil.formatRequest(data);

        this.navComponents = [
          {
            id: NewRequestStatus.ITEMS,
            component: <ItemsComponent
              requestNav={this.requestNav}
              data={data}
              isEdit
              cancel={this.cancel}
              onRequestDataChanged={this.onRequestDataChanged}
              updateRequestData={this.updateRequestData} />
          },
          {
            id: NewRequestStatus.DELIVERY_DETAIL,
            component: <DeliveryComponent
              requestNav={this.requestNav}
              data={data}
              isEdit
              cancel={this.cancel}
              onRequestDataChanged={this.onRequestDataChanged}
              updateRequestData={this.updateRequestData} />
          },
          {
            id: NewRequestStatus.COMPLETE,
            component: ''
          },
          {
            id: NewRequestStatus.ERROR,
            component: 'Sorry, either the page does not exist or you do no have access'
          }
        ];

        if (data.status !== RequestUtil.RequestStatus.OPEN
          && data.status !== RequestUtil.RequestStatus.EXPIRED) {
          this.requestNav(NewRequestStatus.ERROR);
        } else {
          // finished updating state, change content
          const query = this.props.location.search.substr(1);
          const pair = query.split("=");
          this.requestNav(Number(pair[1]));
        }
      }).catch(error => {
        logError(this.props.firebase.getUidSafe(), Tag.REQUEST_EDIT, {
          ...error,
          networkCall: "getRequestDetail",
          orderId: this.orderId
        });
      });
  }

  render() {
    return (
      <div>
        { this.state.mainPage }
      </div>
    );
  }
}

export default compose(
  withRouter,
  withFirebase,
  withAppContext,
  withBasicAuthorization
)(EditRequestComponent);