import * as React from "react";

function SvgIconHouse(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 11" fill="none" {...props}>
      <path
        d="M4.833 10.667v-3.5h2.334v3.5h2.916V6h1.75L6 .75.167 6h1.75v4.667h2.916z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgIconHouse;
