import React from 'react';

import { withFirebase } from '../../Firebase';
import AuthEmailFormController from '../AuthEmailFormController';
import { OnBoardingStatus } from "../OnBoardingStatus";

const AuthSignUpBase = (props) => {
  return <AuthEmailFormController
    requestNav={props.requestNav}
    firebaseMethod={props.firebase.doCreateUserWithEmailAndPassword}
    altMethod={OnBoardingStatus.SIGN_IN}
    title="Sign Up"
    altText="Already have an account?"
    altLinkText="Log in"
  />
};

export default withFirebase(AuthSignUpBase);