import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import AuthSignIn from './views/AuthSignIn';
import AuthSignUp from './views/AuthSignUp';
import RoleSelectComponent from './RoleSelectComponent';
import ProfileComponent from './ProfileController';
import { withAppContext, AppContent, AppEvents } from '../App';
import { OnBoardingStatus } from "./OnBoardingStatus";
import { logError, Tag } from "../Helper/ErrorLogger";

class OnBoardingController extends Component {
  requestNav = (navTargetId) => {
    const target = this.navComponents.find( candidate => candidate.id === navTargetId );
    if (target) {
      this.setState({
        mainPage: target.component,
      });
      if (target.id === OnBoardingStatus.ONBOARD_EXIT) {
        this.props.history.push(ROUTES.REQUESTS);
      } else {
        this.props.app.postEvent(AppEvents.CONTENT_CHANGED, AppContent.OnBoarding);
      }
    } else {
      logError(this.props.firebase.getUidSafe(), Tag.ONBOARD, {
        reason: 'Cannot find navigation target with ID '+navTargetId
      });
    }
  }

  navComponents = [
    {
      id: OnBoardingStatus.SIGN_IN,
      component: <AuthSignIn requestNav={this.requestNav} />
    },
    {
      id: OnBoardingStatus.SIGN_UP,
      component: <AuthSignUp requestNav={this.requestNav} />
    },
    {
      id: OnBoardingStatus.ROLE_SELECT,
      component: <RoleSelectComponent requestNav={this.requestNav} />
    },
    {
      id: OnBoardingStatus.PROFILE,
      component: <ProfileComponent requestNav={this.requestNav} />
    },
    {
      id: OnBoardingStatus.ONBOARD_EXIT,
      component: ''
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      mainPage: '',
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        if (authUser) {
          // now if user has no profile, create; otherwise, proceed to app
          this.props.firebase.user(authUser.uid).once('value', snapshot => {
            const profile = snapshot.val();
            if (profile) {
              if (profile.role) {
                // todo: need more thorough check for profile completion
                if (profile.fullName) {
                  this.requestNav(OnBoardingStatus.ONBOARD_EXIT);
                } else {
                  this.requestNav(OnBoardingStatus.PROFILE);
                }
              } else {
                this.requestNav(OnBoardingStatus.ROLE_SELECT);
              }
            } else {
              this.props.firebase.user(this.props.firebase.auth.currentUser.uid)
                .update({
                  email: authUser.email
                }).catch(error => {
                  logError(this.props.firebase.getUidSafe(), Tag.ONBOARD, {
                    ...error,
                    networkCall: "updateUserEmail"
                  });
                });
              this.requestNav(OnBoardingStatus.ROLE_SELECT);
            }
          }).catch(error => {
            logError(this.props.firebase.getUidSafe(), Tag.ONBOARD, {
              ...error,
              networkCall: "getUserProfile"
            });
          });
        } else {
          // remove question mark
          const query = new URLSearchParams(this.props.location.search);

          const type = query.get('type');
          if (type === 'v') {
            this.props.firebase.trackEvent('landing_volunteer');
          } else if (type === 'r') {
            this.props.firebase.trackEvent('landing_requestor');
          }

          const action = query.get('action');
          if (action === 'signup') {
            this.requestNav(OnBoardingStatus.SIGN_UP);
          } else if (action === 'signin') {
            this.props.firebase.trackEvent('landing_log_in');
            this.requestNav(OnBoardingStatus.SIGN_IN);
          } else {
            this.requestNav(OnBoardingStatus.SIGN_IN);
          }
        }
      },
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <div>
        { this.state.mainPage }
      </div>
    );
  }
}

export default compose(
  withAppContext,
  withRouter,
  withFirebase
)(OnBoardingController);