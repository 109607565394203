import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

import AuthFacebook from './AuthFacebook';
import AuthGoogle from './AuthGoogle';
import * as ROUTES from '../../../constants/routes';
import { AuthBaseStyles } from "../../../constants/authConstants";

class AuthEmailForm extends Component {

  constructor(props) {
    super(props);
    this.styles = props.classes;
  }
 
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
      	<Card className={this.styles.cardRoot}>
          <CardContent>
            <Typography 
              variant="h5"
              className={this.styles.cardTitle}>
              {this.props.title}
            </Typography>
            <Typography 
              variant="body1" 
              className={this.styles.topMarginLarge}
              >
              {this.props.altText} <Link 
                onClick={this.props.onAltMethodRequested} 
                variant="body1" 
                href="#" 
                to="#" 
                className={this.styles.hyperlink}>
                {this.props.altLinkText}
              </Link>
            </Typography>
            <FormHelperText error>
              {this.props.error && this.props.error.message}
            </FormHelperText>
  	        <TextField
  	          name="email"
              id="email"
  	          value={this.props.email}
              className={this.styles.topMarginLarge}
  	          onChange={this.props.handleChange('email')}
  	          label="Email Address"
  	          variant="outlined"
              fullWidth
  	        />
            <FormControl 
              fullWidth 
              variant="outlined" 
              className={this.styles.topMarginLarge}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={this.props.showPassword ? 'text' : 'password'}
                value={this.props.password}
                onChange={this.props.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.props.handleChange('showPassword')}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end">
                      {this.props.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <Button
              className={this.styles.topMarginSmall}
              fullWidth
              disabled={this.props.isInvalid()}
              variant="contained"
              color="primary"
              type="submit">
              Next
            </Button>
            <div className={this.styles.divider}>
              <Typography
                component="span"
                className={this.styles.dividerCenterText}>
                Or
              </Typography>
            </div>
            <AuthGoogle
              handleSubmitProvider={this.props.handleSubmitProvider}
              className={this.styles.topMarginLarge} />
            <AuthFacebook
              handleSubmitProvider={this.props.handleSubmitProvider}
              className={this.styles.topMarginSmall} />
            <Typography
              variant="body1"
              className={this.styles.topMarginLarge}>
              By signing up, you agree to our <Link
                to={ROUTES.TERMS}
                target="_blank"
                className={this.styles.hyperlink}>
                terms and conditions
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </form>
    );
  }
}

export default withStyles(AuthBaseStyles)(AuthEmailForm);