import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';

import { CommonStyle } from '../../../../constants/styles';
import * as RequestUtil from '../../../../constants/requestConstants';
import { FORM_DELIVERY_FEEDBACK } from "../../../../constants/routes";

class RequestFeedbackModal extends Component {

  constructor(props) {
    super(props);
    this.styles = props.classes;
  }

  render() {
    return (<Modal
      aria-labelledby="feedback-modal-title"
      aria-describedby="feedback-modal-desc"
      className={this.styles.modal}
      open={this.props.isModalShowing}
      onClose={this.props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={this.props.isModalShowing}>
        <div className={this.styles.modalUi}>
          <Typography 
            id="feedback-modal-title"
            className={this.styles.cardTitle}>
            Share your feedback
          </Typography>
          <Typography 
            id="feedback-modal-desc"
            variant="body1">
            To help us improve the experience of WeCare, we would love to hear from you&nbsp;
            <Link 
              href={FORM_DELIVERY_FEEDBACK}
              target="_blank"
              className={this.styles.hyperlink}>
              here
            </Link>. 
          </Typography>
          <Grid justify="flex-end" container>
            <Button 
              variant="contained"
              color="primary" 
              className={this.styles.modalButton}
              onClick={this.props.closeModal}>
              Done
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>)
  }
}

const mergedStyles = { ...CommonStyle, ...RequestUtil.CommonRequestStyles };
export default withStyles(mergedStyles)(RequestFeedbackModal);