import React from 'react';
import clsx from 'clsx';

import { IconWarning } from '../icons';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const RequestListEvent = {
	REFRESH_SUCCEEDED: 'OrdersRefreshSucceeded',
	REFRESH_ERROR: 'OrdersRefreshError'
};

export const RequestStatus = {
  OPEN: 0,
  PENDING: 1,
  EXPIRED: 2,
  COMPLETED: 3,
};
export const isExpired = requestStatus => requestStatus === RequestStatus.EXPIRED;
export const isOpen = requestStatus => requestStatus === RequestStatus.OPEN;
export const isPending = requestStatus => requestStatus === RequestStatus.PENDING;
export const isCompleted = requestStatus => requestStatus === RequestStatus.COMPLETED;

export const AuthStatus = {
	UNKNOWN: 0,
	REQUESTER: 1,
	VOLUNTEER: 2,
};
export const isMyRequest = requestAuthStatus => requestAuthStatus !== AuthStatus.UNKNOWN;
export const iAmRequester = requestAuthStatus => requestAuthStatus === AuthStatus.REQUESTER;
export const iAmVolunteer = requestAuthStatus => requestAuthStatus === AuthStatus.VOLUNTEER;

const RequestStatusLabels = [
	"Open",
	["Pending Delivery", "Accepted"],
	"Expired",
	"Completed"
];

export const timeslotCandidates = [
  {
    hourOffset: 8,
    duration: 3,
    displayText: "8:00AM - 11:00AM"
  }, {
    hourOffset: 11,
    duration: 3,
    displayText: "11:00AM - 2:00PM"
  }, {
    hourOffset: 14,
    duration: 3,
    displayText: "2:00PM - 5:00PM"
  },{
    hourOffset: 17,
    duration: 3,
    displayText: "5:00PM - 8:00PM"
  }
];

export const labelHelpNeeded = 'Help Needed';
export const labelDeliverFor = 'Deliver for ';

export const CommonRequestStyles = {
	statusLabel: {
  	color: '#D14904',
  },
  statusLabelOpen: {
		color: '#5C5C5C',
  },
  statusLabelComplete: {
  	color: '#04857F',
  },
  deliveryTimes: {
  	display: 'inline',
  },
  deliveryTimeLineTow: {
  	display: 'block',
  	marginTop: '-3px',
  },
  deliveryWarningIcon: {
  	marginLeft: '7px',
  	width: '15px',
  	display: 'inline',
		position: 'relative',
		top: '2px'
  },
  expireText: {
  	color: '#D14904',
  	fontSize: '14px',
  	lineHeight: '21px',
  },
};

export const getExpireText = (isExpired, styles) => {
	return isExpired ?
        	(<Typography className={styles.expireText}>
          	Add a new delivery date to have your request visible to volunteers. 
          </Typography>) : null
};

export const getDliveryInfo = (status, volunteerName) => {
	if (status === RequestStatus.COMPLETED) {
		return volunteerName + ' delivered for you.';
	} else if (status === RequestStatus.PENDING) {
		return volunteerName + ' is delivering for you.';
	} else {
		return '';
	}
};

export const getTitle = (iAmRequester, requesterName) => {
	return iAmRequester ? labelHelpNeeded : labelDeliverFor + requesterName;
};

export const renderRequestStatusLabel = (request, styles) => {
	const labelStyle = clsx({
		[styles.statusLabel]: true,
		[styles.statusLabelOpen]: request.status === RequestStatus.OPEN,
		[styles.statusLabelComplete]: request.status === RequestStatus.COMPLETED
			|| (request.authStatus === AuthStatus.UNKNOWN && request.status === RequestStatus.PENDING),
	});
	
	let labelText = "";
	if (request.authStatus === AuthStatus.UNKNOWN && request.status !== RequestStatus.OPEN) {
		labelText = "Accepted by "+request.volunteerName;
	} else if (request.status === RequestStatus.PENDING) {
		labelText = (request.authStatus === AuthStatus.REQUESTER ? RequestStatusLabels[1][1] : RequestStatusLabels[1][0]);
	} else {
		labelText = RequestStatusLabels[request.status];
	}

	return (<Typography className={labelStyle}>{labelText}</Typography>);
};

export const renderTimeSummayLabel = (deliverySlots, styles, isExpired) => {
	let lineOne, lineTow;
	const dateFormat = {weekday: 'short', day: 'numeric', month: 'short'};
	const timeFormat = {hour: 'numeric', minute: '2-digit'};

	if (deliverySlots.length === 1) {
		const date = new Date(deliverySlots[0].date);
		lineOne = date.toLocaleDateString(undefined, dateFormat);
	} else {
		const firstDate = new Date(deliverySlots[0].date);
		const lastDate = new Date(deliverySlots[deliverySlots.length-1].date);
		lineOne = firstDate.toLocaleDateString(undefined, dateFormat)
			+ " - "
			+ lastDate.toLocaleDateString(undefined, dateFormat);
	}

	if (deliverySlots.length === 1 && deliverySlots[0].timeslots.length === 1) {
		const startTime = new Date(deliverySlots[0].timeslots[0].start);
		const startTimeString = startTime.toLocaleTimeString(undefined, timeFormat);
		startTime.setHours(startTime.getHours() + deliverySlots[0].timeslots[0].duration);
		const endTimeString = startTime.toLocaleTimeString(undefined, timeFormat);
		lineTow = startTimeString + " - " + endTimeString;
	} else {
		lineTow = "Multiple times available";
	}

	return (
		<Grid container alignItems="flex-start">
			<Typography className={clsx(styles.deliveryTimes, styles.lineBreakText)}>
				{lineOne}
				{ isExpired ? <IconWarning className={styles.deliveryWarningIcon} /> : null}
				{"\n"+lineTow}
			</Typography>
		</Grid>
	); 
};

export const renderSelectedTimeLabel = (selectedSlot, styles) => {
	const dateFormat = {weekday: 'short', day: 'numeric', month: 'short'};
	const dateObj = new Date(selectedSlot);

	let timeSlot = timeslotCandidates.find(candidate => candidate.hourOffset === dateObj.getHours());
	if (!timeSlot) {
		timeSlot = { displayText: 'Error. Please contact us.' };
	}

	return (
		<Typography className={styles.lineBreakText}>
			{ dateObj.toLocaleDateString(undefined, dateFormat)+"\n"+timeSlot.displayText }
		</Typography>
	);
};

export const formatRequest = (request, userId) => {
	// sort delivery Timeslots
	request.deliveryTimeslots.sort((a, b) => a.date - b.date);

	// set status to expired if necessary
	const latestDeliveryDate = request.deliveryTimeslots[request.deliveryTimeslots.length-1].date
	const today = new Date();
  today.setHours(0,0,0,0);
	if (request.status === RequestStatus.OPEN && latestDeliveryDate < today.getTime()) {
		request.status = RequestStatus.EXPIRED;
	}

	// add auth status
	if (request.volunteer === userId) {
		request.authStatus = AuthStatus.VOLUNTEER;
	} else if (request.requestee === userId) {
		request.authStatus = AuthStatus.REQUESTER;
	} else {
		request.authStatus = AuthStatus.UNKNOWN;
	}
};

export const getTomorrowDate = () => {
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	tomorrow.setHours(0,0,0,0);
	return tomorrow;
}

export const getAddress = (isFullAddress, addressObj) => {
	if (typeof addressObj === "string") {
		return addressObj;
	} else if (typeof addressObj === "object") {
		let linePostal;
		if (!!addressObj.postal) {
			linePostal = '\n' + addressObj.postal;
		} else {
			// postal code is missing, do not display line one
			linePostal = '';
		}

		const lineCityProvince = addressObj.city + ', ' + addressObj.province;
		if (isFullAddress) {
			return addressObj.address + '\n'
				+ lineCityProvince
				+ linePostal;
		} else {
			return lineCityProvince + linePostal;
		}
	} else {
		return "";
	}
}