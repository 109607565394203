import { CommonStyle } from './styles';

const AuthStyles = {
  divider: {
    textAlign: 'center',
    borderBottom: '1px solid #A3A3A3',
    marginTop: '26px',
    lineHeight: '0.1em',
  },
  dividerCenterText: {
    background:'white',
    color: '#5C5C5C',
    lineHeight: '0.1em',
    padding:'0 6px'
  },
  topMarginLarge: {
    marginTop: '26px',
  },
  topMarginSmall: {
    marginTop: '16px',
  },
};
const AuthBaseStyles = { ...CommonStyle, ...AuthStyles };

export { AuthBaseStyles };