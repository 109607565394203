export const AppContent = {
  Landing: 0,
  OTHER_NO_AUTH: 1,
  OnBoarding: 2,
  Profile: 3,
  NewRequest: 4,
  EditRequest: 5,
  // Content without special actions
  Other: 6,
};

export const AppEvents = {
  CONTENT_CHANGED: 'ContentChanged',
  UPDATE_DATA: 'UpdateData',
  ALLOW_NAV: 'AllowNav'
};

export const AppDataKey = {
  SHOW_MY_REQUEST_ONLY: 'showMyRequestOnly',
}

export const LABEL_BACK_TO_ALL_REQUEST = "Back to Community Requests";
export const LABEL_BACK_TO_MY_REQUEST = "Back to Your Requests";

export const IS_DEV = process.env.REACT_APP_STAGE === 'dev'
export const IS_PROD = process.env.REACT_APP_STAGE === 'prod'